import * as Sentry from '@sentry/react';
import type { RouteObject } from 'react-router';
import { createBrowserRouter } from 'react-router';
import capitalize from 'lodash/capitalize';

export enum ApplicationDomain {
  Loans = 'Loans',
  Retainers = 'Retainers',
  Admin = 'Admin',
  Profile = 'Profile',
  Trading = 'Trading',
}

const sentryCreateBrowserRouter = Sentry.wrapCreateBrowserRouter(createBrowserRouter);

class SkyRouter {
  router: ReturnType<typeof sentryCreateBrowserRouter> | null;

  constructor() {
    this.router = null;
  }

  init(routerConfig: RouteObject[]) {
    if (this.router) {
      return this.router;
    }
    this.router = sentryCreateBrowserRouter(routerConfig, {});
    return this.router;
  }

  getDomain(url?: string | URL): ApplicationDomain | undefined {
    let domainPath;

    if (url) {
      const urlObject = typeof url === 'string' ? new URL(url) : url;
      [, domainPath] = urlObject.pathname.split('/');
    } else {
      domainPath = this.router?.state.location.pathname.split('/')[1];
    }
    return domainPath ? (capitalize(domainPath) as ApplicationDomain) : undefined;
  }

  getPathname(url?: string | URL) {
    if (url) {
      const urlObject = typeof url === 'string' ? new URL(url) : url;
      return urlObject.pathname;
    }
    return this.router?.state.location.pathname ?? '';
  }
}

export const skyRouter = new SkyRouter();
