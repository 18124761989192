import type { GenericError } from 'src/store/utils/errors';
import type { RequestStatus } from 'src/store/types';
import type { JsonAccount } from 'src/store/shared/accounts/types';

export enum ApiCommands {
  APIKeys = 'APIKeys',
  GetAPIKey = 'GetAPIKey',
  CreateAPIKey = 'CreateAPIKey',
  UpdateAPIKey = 'UpdateAPIKey',

  LinkLoan = 'LinkLoan',
  UnlinkLoan = 'UnlinkLoan',

  CreateSubAccount = 'CreateSubAccount',
  DeleteAPIKey = 'DeleteAPIKey',
}

export interface ApiKeyItem {
  APIKey: string;
  AccountID: string;
  ExchangeID: number;
  Name: string;
  Status: number;
  Label: string;
  LoanID: number;
  ID: number;
  ProjectID: number;
  LinkedAccounts: JsonAccount[];
}

export type JsonApiKeyItem = ApiKeyItem & {
  exchange: number;
  exchangeName: string;
  LoanName?: string;
  ProjectName?: string;
};

interface LoanID {
  loanID: number;
}

interface ProjectID {
  projectID: number;
}

export type LinkProjectPayload = {
  accountID?: string;
} & (LoanID | ProjectID);

export type InitialLinkValuesApiKey = {
  accountID: string | number;
  action: 'link' | 'unlink';
} & LinkProjectPayload;

export interface ExchangeItem {
  id: string | number | symbol;
  name: string;
  comment: string | null;
  video: string | null;
  ips: string;
  pub?: { length: number; mask: RegExp };
  secret?: { length: number; mask: RegExp };
  passphrase?: { label?: string; numbersOnly?: boolean; optional?: boolean };
  crypted: boolean;
}

export interface JsonApiKey {
  okCommand: string;
  id: string;
  command: 'ok';
  optional: string;
}

export type ExchangeObjItems = Record<string, ExchangeItem>;

export type ModalType = 'new' | 'link' | 'sub';

export interface InitialNewValuesApiKey {
  AccountID: string;
  ID: number;
  exchange: number;
}

export interface ApiKeysState {
  error: null | GenericError;

  requestStatus: RequestStatus;
  isOpenModal: boolean;
  modalInitialValue?:
    | InitialNewValuesApiKey
    | InitialLinkValuesApiKey
    | InitialAddSubAccountValuesApiKey;
  list: ApiKeyItem[];

  exchange: ExchangeObjItems;
  reqApiKey: RequestStatus;
  reqGetEncryptedToken: RequestStatus;

  selectedExchange: number | undefined;

  reqLinkApi: RequestStatus;
  typeModal?: ModalType;
}

export interface OpenModalType {
  type: ModalType;
  initialValues?:
    | InitialNewValuesApiKey
    | InitialLinkValuesApiKey
    | InitialAddSubAccountValuesApiKey;
}

export type SetObjInitialNewValuesApiKey = Record<string, InitialNewValuesApiKey>;
export type SetObjInitialLinkValues = Record<string, LinkProjectPayload>;

export enum AdminApiKeyEndpoints {
  Exchange = '/me/exchanges',
}

export interface CreateApiKeyPayload {
  apiKey: string;
  exchange: number;
  label: string;
  name: string;
  secret?: string;
  passphrase?: string;
}

export interface UpdateApiKeyPayload {
  id: number;
  label: string;
}

export interface CreateSubAccountPayload {
  exchange: number;
  name: string;
  label: string;
}

export interface DeleteApiKeyPayload {
  exchange: number;
  label: string;
}

export type InitialAddSubAccountValuesApiKey = Omit<CreateSubAccountPayload, 'name'>;
