import type { JsonProjectAdmin, JsonUserAccount } from 'src/store/retainers/admin-panel/slice';

export enum AdminPanelCommands {
  AdminPanel = 'AdminPanel',
  BindAccount = 'BindAccount',
}

export interface JsonAdminPanelResponse {
  command: AdminPanelCommands.AdminPanel | 'ok' | 'error';
  accounts: JsonUserAccount[];
  projects: JsonProjectAdmin[];
}

export interface JsonBindAccountPayload extends JsonAdminPanelResponse {
  okCommand: AdminPanelCommands.BindAccount;
  message: string | null;
  result: string;
}

export interface BindUnbindAccountResult {
  projectID: number;
  accountID: number;
  direction: 0 | 1;
}
