import { createSelector } from '@reduxjs/toolkit';

import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import first from 'lodash/first';
import some from 'lodash/some';

import { selectExchanges } from 'src/store/shared/exchanges/selectors';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type { AccountsState } from 'src/store/shared/accounts/types';
import type { AutoCompleteItem } from 'src/store/types';
import type { LoansState, JsonLoan } from './types';
import type { RootState } from 'src/store';
import { filterAndSort } from 'src/store/utils';

interface Store {
  loans: {
    index: LoansState;
  };
  accounts: AccountsState;
}

export const selectError = (store: Store) => store.loans.index.error;
export const selectIsListenSent = (store: Store) => store.loans.index.listenStatus === 'success';

export const selectLoansList = (store: Store) => store.loans.index.list;
export const selectAllowedLoans = (state: RootState) => state.loans.index.allowedLoansList;
export const selectIsLoadingAllowedLoans = (state: RootState) =>
  state.loans.index.isLoadingAllAllowed === 'pending';
export const selectCompanies = (store: Store) => store.loans.index.companies;

export const selectComments = (store: Store) => store.loans.index.comments;
export const selectIsOverviewLoading = (store: Store) => store.loans.index.isOverviewLoading;

export const selectIsNewLoanCommentModalOpen = (store: Store) =>
  store.loans.index.isNewLoanCommentModalOpen;
export const selectIsCommentSubmitting = (store: Store) =>
  store.loans.index.resUpdateComment === 'pending';
export const selectIsCommentSubmitted = (store: Store) =>
  store.loans.index.resUpdateComment === 'success';

export const selectIsNewLoanModalOpen = (store: Store) => store.loans.index.isNewLoanModalOpen;
export const selectIsLoanSubmitting = (store: Store) =>
  store.loans.index.resNewLoan === 'pending' || store.loans.index.resUpdateLoan === 'pending';
export const selectIsLoanSubmitted = (store: Store) =>
  store.loans.index.resNewLoan === 'success' || store.loans.index.resUpdateLoan === 'success';

export const selectIsSidebarOpen = (store: Store) => store.loans.index.isSidebarOpen;
export const selectTargetLoan = (store: Store) => store.loans.index.targetLoan;
export const selectTargetExchange = (store: Store) => store.loans.index.targetExchange;

export const selectOverviewActiveTab = (store: Store) => store.loans.index.overviewActiveTab;

export const selectTermChangesLog = (store: Store) => store.loans.index.termChangesLog;
export const selectIsTermChangesLogLoading = (store: Store) =>
  store.loans.index.termsChangesLogLoadingStatus === 'pending';

export const selectSortKey = (state: RootState) => state.loans.index.sortKey;
export const selectSearchInput = (state: RootState) => state.loans.index.searchInput;

// company options/suggestions for autocomplete in NewLoanModal form

export const selectCompanyOptions = createSelector(selectCompanies, (items: string[]) => {
  const companyOptions: AutoCompleteItem[] = items.map((company: string) => ({
    key: company,
    value: company,
    label: company,
  }));

  return companyOptions;
});

// bot table with exchange details

// currentLoan could be:
//  - undefined – the initial value
//  - JsonLoan – if currentLoan is selected from the loans list
//  - null - if currentLoan could not be found in the loans list
export const selectCurrentLoan = createSelector(
  selectTargetLoan,
  selectAllowedLoans,
  (targetLoan: string, items: JsonLoan[] | null) => {
    if (items === null || !targetLoan) {
      return undefined;
    }

    return find(items, { id: +targetLoan }) ?? null;
  },
);

export const getDefaultLoanFromList = (items: JsonLoan[] | null) =>
  first(sortBy(items, 'id')) ?? null;

export const selectDefaultLoan = createSelector(selectAllowedLoans, (items) =>
  items ? getDefaultLoanFromList(items) : null,
);
export const selectNextLoan = createSelector(
  selectTargetLoan,
  selectAllowedLoans,
  selectSortKey,
  (targetLoan: string, items: JsonLoan[] | null, sortKey: string) => {
    if (!items || !targetLoan) return undefined;

    const sortedItems = filterAndSort(items, sortKey);
    const currentIndex = findIndex(sortedItems, { id: +targetLoan });
    const nextIdx = currentIndex + 1;
    return nextIdx < sortedItems.length ? sortedItems[nextIdx] : undefined;
  },
);

export const selectPrevLoan = createSelector(
  selectTargetLoan,
  selectAllowedLoans,
  selectSortKey,
  (targetLoan: string, items: JsonLoan[] | null, sortKey: string) => {
    if (!items || !targetLoan) return undefined;

    const sortedItems = filterAndSort(items, sortKey);
    const currentIndex = findIndex(sortedItems, { id: +targetLoan });
    const prevIdx = currentIndex - 1;
    return prevIdx >= 0 ? sortedItems[prevIdx] : undefined;
  },
);

export const selectCurrentExchange = createSelector(
  selectTargetExchange,
  selectExchanges,
  (targetExchange: string, items: JsonExchange[]) => {
    const currentExchange: JsonExchange | undefined = targetExchange
      ? (find(items, { id: +targetExchange }) ?? undefined)
      : undefined;
    return currentExchange;
  },
);

export const selectLoanLogMessagesCommitedFunds = createSelector(selectTermChangesLog, (messages) =>
  messages
    .filter((entry) => entry.Message.startsWith('Committed funds'))
    .map((entry, idx) => ({ ...entry, key: entry.ID! || idx })),
);

export const selectSortedLoans = createSelector(
  selectAllowedLoans,
  selectSortKey,
  selectSearchInput,
  (loans, sortKey, searchInput) => filterAndSort(loans ?? [], sortKey, searchInput),
);

export const selectIsLoanExist = (loanId?: string) =>
  createSelector(selectLoansList, (list) => {
    if (list === null || loanId === undefined) {
      return undefined;
    }
    return some(list, { id: Number(loanId) });
  });
