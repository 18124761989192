import type {
  BaseQueryApi,
  BaseQueryFn,
  FetchArgs,
  FetchBaseQueryError,
} from '@reduxjs/toolkit/query/react';
import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';

import type { RootState } from 'src/store';
import { AUTH_BASE_URL } from 'src/constants';

import { Mutex } from 'async-mutex';
import { selectAccessToken, selectIsEmailConfirmed } from 'src/store/signup/selectors';
import { AuthApiActionTypes, AuthApiEndpoints } from 'src/store/shared/rtkAuthApi/types';
import { getTokenPayload, shouldSkipReauth } from 'src/store/shared/rtkAuthApi/helpers';
import { skyRouter } from 'src/sky-router';

const mutex = new Mutex();

// TODO: Refactor token refresh logic the way it's not require store pass as an argument
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const ensureAccessToken = async (store: any, accessToken?: string) => {
  const isMissing = !accessToken;
  const isExpired = !isMissing && getTokenPayload(accessToken).isTokenExpired;
  if (isMissing || isExpired) {
    await attemptTokenRefresh(store as BaseQueryApi, {});
  }
};

async function attemptTokenRefresh(api: BaseQueryApi, extraOptions: object = {}) {
  const refreshQuery = fetchBaseQuery({
    baseUrl: AUTH_BASE_URL,
    credentials: 'include',
  });

  const refreshResult = await refreshQuery(
    { url: AuthApiEndpoints.RefreshToken, method: 'POST' },
    api,
    extraOptions,
  );
  // console.debug('>>> refreshResult:', refreshResult);

  if (refreshResult.data) {
    api.dispatch({ type: AuthApiActionTypes.SetAccessToken, payload: refreshResult.data });
    return (refreshResult.data as { accessToken: string }).accessToken;
  } else if (refreshResult?.error?.status === 401) {
    await skyRouter.router?.navigate('/signout');
  }
}

export const ReauthBaseQuery =
  (
    customBaseQuery: BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError>,
  ): BaseQueryFn<string | FetchArgs, unknown, FetchBaseQueryError> =>
  async (args, api, extraOptions) => {
    await mutex.waitForUnlock();

    const isEmailConfirmed = selectIsEmailConfirmed(api.getState() as RootState) || false;

    if (!shouldSkipReauth(args)) {
      const accessToken = selectAccessToken(api.getState() as RootState) ?? '';
      if ((!accessToken || getTokenPayload(accessToken).isTokenExpired) && !isEmailConfirmed) {
        await attemptTokenRefresh(api, extraOptions);
      }
    }

    let result = await customBaseQuery(args, api, extraOptions);

    if (result.error && result.error.status === 401 && !shouldSkipReauth(args)) {
      if (!mutex.isLocked()) {
        const release = await mutex.acquire();
        try {
          await attemptTokenRefresh(api, extraOptions);
          result = await customBaseQuery(args, api, extraOptions);
        } finally {
          release();
        }
      } else {
        await mutex.waitForUnlock();
        result = await customBaseQuery(args, api, extraOptions);
      }
    }

    return result;
  };
