import { createSlice } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import type { GenericError } from 'src/store/utils/errors';
import type { JsonSuccess, RequestStatus } from 'src/store/types';
import { ReportCommands } from './types';

import { cancelReport, confirmReport, requestReport } from './actions';

export interface ReportsState {
  requestStatus: RequestStatus;
  error: GenericError | null;

  reportPreview: string | null;
  reportId: number | null;
}

const allowedCommands = Object.values(ReportCommands);

type JsonReportCommand = 'GetReport' | 'SendReport';

interface JsonReportSuccess extends JsonSuccess {
  okCommand: JsonReportCommand;
  id: number;
  result: string;
}

const initialState: ReportsState = {
  requestStatus: null,
  error: null,

  reportPreview: null,
  reportId: null,
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {
    resetPreview: (state) => {
      state.reportPreview = null;
      state.requestStatus = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(requestReport.pending, (state) => {
      state.requestStatus = 'pending';
    });

    builder.addCase(requestReport.rejected, (state) => {
      state.requestStatus = 'error';
    });

    builder.addCase(cancelReport.fulfilled, (state) => {
      state.reportId = null;
      state.reportPreview = null;
      state.requestStatus = null;
    });

    builder.addCase(confirmReport.fulfilled, (state) => {
      state.reportId = null;
      state.reportPreview = null;
    });

    // builder.addCase(requestReport.fulfilled, (state) => {});

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      console.debug(`reports/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      const { command, okCommand } = json;

      if (error) {
        state.error = error;
        state.requestStatus = 'error';
        return;
      }

      if (command === 'ok') {
        const { id, result } = json as JsonReportSuccess;
        switch (okCommand) {
          case ReportCommands.GetReport:
            if (id && result) {
              state.reportPreview = result;
              state.reportId = id;
            } else {
              state.requestStatus = 'success';
            }
            break;
          case ReportCommands.SendReport:
            state.requestStatus = 'success';
            state.reportId = null;
            state.reportPreview = null;
            break;
          default:
        }
      }
    });
  },
});

export const reportsActions = {
  ...reportsSlice.actions,
  requestReport,
  confirmReport,
  cancelReport,
};

export default reportsSlice.reducer;
