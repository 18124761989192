import type { GenericError } from 'src/store/utils/errors';

import type { JsonConnection, RequestStatus } from 'src/store/types';

import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type {
  HistoryDataItem,
  JsonBalanceHistoryItem,
} from 'src/store/shared/balance-history/types';
import type { JsonRetainersPiechartData } from 'src/store/retainers/types';

export enum BalanceCommands {
  Balances = 'Balances',
  CloseSmallTokens = 'CloseSmallTokens',
  AddBalance = 'AddBalance',
}

export interface NewComparisonItem {
  startDate: string;
  endDate: string;
  startData: HistoryDataItem | null;
  startHistoryItem: JsonBalanceHistoryItem;
  endData: HistoryDataItem | null;
  endHistoryItem: JsonBalanceHistoryItem;
  diff: HistoryDataItem | null;
  isSmallBalance: boolean;
}

export interface JsonBalanceAsset {
  token: string;
  lastPrice: number;
  volume: number;
  volumeHint?: string;
  volumeUSD: number;
  volumeUSDHint?: string;
  leverage: number;
  type: number; // 0 - quote, 1 - base, 2 - other
  // extras
  isDoubling?: boolean;
  isSmallBalance?: boolean;
  chain?: string;
}

export const historyItemTypeMap: Record<string, string> = {
  0: 'quote',
  1: 'base',
  2: 'other',
};

export interface JsonBalanceHistoryAsset {
  token: string;
  lastPrice: number;
  leverage: number;
  // extras for comparison
  comparison: NewComparisonItem;
  history: JsonBalanceHistoryItem[];
  isDoubling?: boolean;
  isSmallBalance?: boolean;
  type: string; // 'quote' | 'base' | 'other'
}

export interface JsonBalance {
  account?: JsonConnection;
  accountID: string;
  accountName: string;
  accountLabel: string;
  assets: JsonBalanceAsset[];
  exchangeID: number;
  exchangeName?: string;
  updated?: string;
}

interface JsonBalanceExtention {
  key: string;
  exchange?: JsonExchange;
  account?: JsonConnection;
  baseToken?: string;
  quoteToken?: string;
  label?: JsonConnection['label'];
  accountName?: JsonConnection['label'];
  isTotalDoubling?: boolean;
  isDla?: boolean;
  isTotalSmallBalance?: boolean;
  isComparisonSmallBalance?: boolean;
  totalVolumeUSD?: number;
  totalVolumeTooltip?: string;
  groupMap: {
    exchange?: string;
    type?: string; // 'DLA' | 'MM' | 'Other'
  };
  hue: number;

  piechartData: JsonRetainersPiechartData[];

  // future related props
  isSpot: boolean;
  isFuture: boolean;
  isRebalancing: boolean;
  futureId?: string;
  future?: JsonBalanceExtended | JsonBalanceHistoryExtended;
}

export type JsonBalanceExtended = JsonBalanceExtention & JsonBalance;

export type JsonBalanceExtendedList = JsonBalanceExtended &
  JsonBalanceGroup & {
    groupAccountsId: string[];
  };

export type JsonBalanceHistoryExtended = JsonBalanceExtention & {
  // from JsonBalance
  accountID: string;
  // accountName: string;
  accountLabel: string;
  assets: JsonBalanceHistoryAsset[];
  exchangeID: number;
  exchangeName?: string;
};

export type JsonBalanceHistoryExtendedList = JsonBalanceHistoryExtended &
  JsonBalanceGroupBase & {
    groupAccountsId: string[];
  };

interface JsonBalanceGroupBase {
  key: string;
  label: string;
  group: string;

  totalVolume?: number;
  totalVolumeUSD?: number;
}

export type JsonBalanceGroup = JsonBalanceGroupBase & {
  assets: JsonBalanceAsset[];
  children: JsonBalanceExtended[];
};

export type JsonBalanceHistoryGroup = JsonBalanceGroupBase & {
  assets: JsonBalanceHistoryAsset[];
  children: JsonBalanceHistoryExtended[];
};

export interface JsonBalancesSuccess {
  command: 'Balances';
  id?: number;
  supportedTokens: [];
  balances: JsonBalance[];
}

export interface BalancesState {
  error: null | GenericError;
  requestStatus: RequestStatus;

  // Overview balances, data on Balances
  list: JsonBalance[];

  selection: JsonBalance['accountID'][];

  createBalanceSnapshotStatus: RequestStatus;
  createWalletSnapshotStatus: RequestStatus;
  supportedTokens: [];
}

export interface AddBalancePayload {
  accountID: string;
  date: string;
  balances: AddBalancePayloadRecord[];
}

export interface AddBalancePayloadRecord {
  token: string;
  value: number;
  usd: number;
}
