import { LoanCommands } from 'src/store/loans/types';
import { StrikePricesCommands } from 'src/store/loans/strike-prices/types';
import { LoanExchangesCommands } from 'src/store/loans/exchanges/types';
import { LoanFundsCommands } from 'src/store/loans/funds/types';
import { LoanLiquidityCommands } from 'src/store/loans/liquidity/types';
import { LoanProfitCommands } from 'src/store/loans/profit/types';
import { LoanStatsCommands } from 'src/store/loans/stats/types';
import { AdminPanelCommands } from 'src/store/retainers/admin-panel/types';
import { TransactionCommands } from 'src/store/shared/transactions/types';
import { AccountCommands } from 'src/store/shared/accounts/types';
import { LoanKpiCommands } from 'src/store/loans/kpi/slice';
import { RetainerKpiCommands } from 'src/store/retainers/kpi/slice';

export const Commands = {
  Loans: LoanCommands.Loans,
  Overview: LoanCommands.Overview,
  // Pairs: LoanCommands.Pairs,
  Balances: LoanCommands.Balances,
  BalanceHistory: LoanCommands.BalanceHistory,
  Log: LoanCommands.Log,
  LoanStrikes: StrikePricesCommands.LoanStrikes,
  LoanKPI: LoanKpiCommands.KPI,
  RetainerKPI: RetainerKpiCommands.KPI,
  LoanExchanges: LoanExchangesCommands.LoanExchanges,
  LoanFunds: LoanFundsCommands.LoanFunds,
  LoanLiquidity: LoanLiquidityCommands.LoanLiquidity,
  LoanProfit: LoanProfitCommands.LoanProfit,
  LoanStats: LoanStatsCommands.LoanStats,
  AdminPanel: AdminPanelCommands.AdminPanel,
  Transactions: TransactionCommands.Transactions,
  GetTransactions: TransactionCommands.GetTransactions,
  Accounts: AccountCommands.Accounts,
};
