import type { JsonLoan } from 'src/store/loans/types';
import { LoanCommands } from 'src/store/loans/types';
import { CerbosActions } from 'src/utils/cerbos/type';
import { skyCerbosClient } from 'src/sky-ui-lib/SkyAuthz/skyCerbosClient';
import type { Principal } from 'src/sky-ui-lib/SkyAuthz/getPrincipal';

export const getAllowedLoans = async (list: JsonLoan[] = [], principal: Principal) => {
  const res = await skyCerbosClient.checkResources({
    principal,
    resources: list.map((loan) => ({
      resource: {
        kind: LoanCommands.Loans,
        id: loan.id.toString(),
      },
      actions: [CerbosActions.Read],
    })),
  });
  return list.filter((loan) =>
    res.isAllowed({
      resource: { kind: LoanCommands.Loans, id: loan.id.toString() },
      action: CerbosActions.Read,
    }),
  );
};
