import React from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import loadable from '@loadable/component';
import { App as AntApp } from 'antd';
import { store, persistor } from 'src/store';

const Theme = loadable(() => import('src/sky-ui-lib/SkyTheme'));
const Intl = loadable(() => import('src/sky-ui-lib/SkyIntl'));
const Authz = loadable(() => import('src/sky-ui-lib/SkyAuthz'));
const Notification = loadable(() => import('src/sky-ui-lib/SkyNotificationContext'));
const Router = loadable(() => import('src/router'));

export function App() {
  return (
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <Intl>
          <Theme>
            <Notification>
              <AntApp>
                <Authz>
                  <Router />
                </Authz>
              </AntApp>
            </Notification>
          </Theme>
        </Intl>
      </PersistGate>
    </Provider>
  );
}
