import { createAsyncThunk } from '@reduxjs/toolkit';
import type { ExtraArgument } from 'src/store';

export const logout = createAsyncThunk<unknown, unknown, { extra: ExtraArgument }>(
  'auth/logout',
  async (_, thunkApi) => {
    await thunkApi.extra.persistor?.purge();
    await thunkApi.extra.persistor?.flush();
  },
);
