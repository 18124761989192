import map from 'lodash/map';
import find from 'lodash/find';
import reduce from 'lodash/reduce';
import toUpper from 'lodash/toUpper';
import reject from 'lodash/reject';
import every from 'lodash/every';
import groupBy from 'lodash/groupBy';
import isNil from 'lodash/isNil';
import flatMap from 'lodash/flatMap';
import filter from 'lodash/filter';
import uniq from 'lodash/uniq';
import keys from 'lodash/keys';
import get from 'lodash/get';
import each from 'lodash/each';
import values from 'lodash/values';
import sortBy from 'lodash/sortBy';
// import memoize from 'lodash/memoize';
// import last from 'lodash/last';
import compact from 'lodash/compact';
import concat from 'lodash/concat';
import first from 'lodash/first';
import mapValues from 'lodash/mapValues';
import uniqBy from 'lodash/uniqBy';
import some from 'lodash/some';
import mean from 'lodash/mean';
import keyBy from 'lodash/keyBy';
import isEmpty from 'lodash/isEmpty';

import type { Key } from 'antd/es/table/interface';

import dayjs from 'src/utils/formatting/dates';
import { createIntl } from 'react-intl';

import type { JsonConnection } from 'src/store/types';
import type {
  JsonBalance,
  JsonBalanceAsset,
  JsonBalanceExtended,
  JsonBalanceExtendedList,
  JsonBalanceHistoryAsset,
  JsonBalanceHistoryExtended,
  JsonBalanceHistoryExtendedList,
  NewComparisonItem,
} from 'src/store/shared/balances/types';
import { historyItemTypeMap } from 'src/store/shared/balances/types';
import type { JsonExchange } from 'src/store/shared/exchanges/types';
import type { JsonTransaction, TransactionObj } from 'src/store/shared/transactions/types';
import type {
  HistoryDataItem,
  JsonBalanceHistoryItem,
  PriceHistoryItem,
  PriceHistoryItemType,
  SetInitialBalanceFormValues,
  SetInitialBalanceModal,
  UniqueTokensList,
} from 'src/store/shared/balance-history/types';
import type {
  BalanceViewChartGroupBy,
  BalanceViewCumulativeBalance,
  BalanceViewGroupBy,
  BalanceViewTarget,
} from 'src/components/BalancesTable/types';

import { defaultIntlConfig, intlCache } from 'src/sky-ui-lib/SkyIntl/config';

import type { TokenOption } from 'src/components/BalancesCorrection/types';
import { getNumberFormatter } from 'src/sky-ui-lib/SkyNumber/utils/formatter';
import {
  getLineColor,
  getLineHue,
  getPieColor,
  BASE_TOKEN_COLOR_NUMBER,
  STABLES_COLOR_NUMBER,
} from 'src/store/utils/color';
import type { LinearDataType, LineChartDataEntry, LineColorOptsType } from 'src/store/utils/color';
import { ApplicationDomain } from 'src/sky-router';

export const smallTokensThreshold = +(import.meta.env.VITE_SMALL_TOKEN_THRESHOLD ?? 25);

const reRebalancing = /(\d+)SF\s/i; // 1111SF - is for spot/future rebalancing account
const reSpot = /(\d+)S\s/i; // 1111S - is for spot
const reFuture = /(\d+)F\s/i; // 1111F - is for future

const reDLA = /(\s)DL(A|\sA\d)(\s|$)/; // dla bots
const reMM = /\sMM\d?(\s|$)/; // ping-pong bots
const intl = createIntl(defaultIntlConfig, intlCache);
const reARBITRAGE = /\sARBITRAGE\d?(\s|$)/;
const reADAPTIVE = /\sADAPTIVE\d?(\s|$)/;
const reICEBERG = /\sICEBERG\d?(\s|$)/;
const reMENKAR = /\sMENKAR\d?(\s|$)/;
const reLIQUIDITY = /\bLIQUIDITY\b/i;
const reFEE = /\sFEE\d?(\s|$)/;

const formatNumber = getNumberFormatter(intl);
const defaultFormat = { format: 'precision' };

const isDla = (label: string) => reDLA.test(label);

// helper to get group name from account label
const getGroupMap = ({
  exchange,
  account,
  isWallet,
}: {
  exchange?: JsonExchange;
  account?: JsonConnection;
  isWallet?: boolean;
}) => {
  const walletsGroup = 'On-Chain Wallets';
  let exchangeGroup = exchange?.name ?? '';
  let accountGroup = '';

  const name = toUpper(account?.label);

  if (isWallet) {
    exchangeGroup = walletsGroup;
  } else if (reARBITRAGE.test(name) && exchangeGroup === '') {
    exchangeGroup = 'Arbitrage';
  }

  switch (true) {
    case reSpot.test(name):
    case reFuture.test(name):
    case reRebalancing.test(name):
      if (reFEE.test(name)) {
        accountGroup = 'Futures Fee';
        break;
      }
      accountGroup = 'Futures DLA';
      break;
    case isDla(name):
      accountGroup = 'DLA';
      break;
    case reMM.test(name):
      accountGroup = 'MM';
      break;
    case reFEE.test(name):
      accountGroup = 'Fee';
      break;
    case isWallet:
      accountGroup = walletsGroup;
      break;
    case reARBITRAGE.test(name):
      accountGroup = 'Arbitrage';
      break;
    case reADAPTIVE.test(name):
    case reICEBERG.test(name):
      accountGroup = 'Sell';
      break;
    case reMENKAR.test(name):
      accountGroup = 'Menkar';
      break;
    case reLIQUIDITY.test(name):
      accountGroup = 'Liquidity';
      break;

    default:
      accountGroup = 'Other';
  }
  return {
    exchange: exchangeGroup,
    type: accountGroup,
  };
};

const isWalletAccount = (accountLabel: string | undefined): boolean =>
  accountLabel?.toLowerCase().includes('wallet') ?? false;

const getToken = (token: string, tokenMap: Record<string, string>): string =>
  tokenMap[toUpper(token)] ?? token;

export const getNewHistoryDataItem = ({
  item,
  isInitialBalanceUsed = false,
  transactionsDiff = { volume: 0, volumeUSD: 0 },
}: {
  item?: JsonBalanceHistoryItem;
  isInitialBalanceUsed?: boolean;
  transactionsDiff?: { volume: number; volumeUSD: number };
}) => {
  const historyData: HistoryDataItem = {
    price: 0,
    volume: 0,
    volumeUSD: 0,
    isSmallBalance: false,
  };

  if (transactionsDiff) {
    if (transactionsDiff.volume !== 0) {
      const opts = { ...defaultFormat, currency: item?.token };
      const strHistoryVolume = formatNumber(historyData.volume, opts);
      const strTransactionsVolume = formatNumber(transactionsDiff.volume, opts);
      historyData.volumeHint = `${strHistoryVolume} + (${strTransactionsVolume} transactions)`;
      historyData.volume += transactionsDiff.volume;
    }
    if (transactionsDiff.volumeUSD !== 0) {
      const opts = { ...defaultFormat, currency: 'USD' };
      const strHistoryVolumeUSD = formatNumber(historyData.volumeUSD, opts);
      const strTransactionsVolumeUSD = formatNumber(transactionsDiff.volumeUSD, opts);
      historyData.volumeUSDHint = `${strHistoryVolumeUSD} + (${strTransactionsVolumeUSD} transactions)`;
      historyData.volumeUSD += transactionsDiff.volumeUSD;
    }
  }

  if (!item) {
    return historyData;
  }

  // 0 - quote, 1 - base, 2 - other

  // check for base
  historyData.price = item.type === 0 ? item?.quotePrice : item?.price;
  const volume =
    !isInitialBalanceUsed ||
    (!isNil(item?.initialAmount) && item?.initialAmount === Number.MIN_SAFE_INTEGER)
      ? item?.historyAmount
      : item?.initialAmount;

  historyData.volume += volume ?? 0;
  historyData.volumeUSD += volume * historyData.price;

  const formatUsd = { ...defaultFormat, currency: 'USD' };
  const formatToken = { ...defaultFormat, currency: item.token };
  const strVolume = formatNumber(volume, formatToken);
  const strVolumeUSD = formatNumber(volume * historyData.price, formatUsd);
  if (transactionsDiff) {
    if (transactionsDiff.volume !== 0) {
      const strTrxDiff = formatNumber(transactionsDiff.volume, formatToken);
      historyData.volumeHint = `${strVolume} + (${strTrxDiff} transactions)`;
    }
    if (transactionsDiff.volumeUSD !== 0) {
      const strTrxDiffUSD = formatNumber(transactionsDiff.volumeUSD, formatUsd);
      historyData.volumeUSDHint = `${strVolumeUSD} + (${strTrxDiffUSD} transactions)`;
    }
  } else {
    historyData.volumeHint = strVolume;
    historyData.volumeUSDHint = strVolumeUSD;
  }

  if (historyData.price !== 0) {
    historyData.isSmallBalance = Math.abs(historyData.volumeUSD) < smallTokensThreshold;
  }

  return historyData;
};

const getTransactionsInRange = ({
  transactions,
  accountId = undefined,
  token = undefined,
  startDate,
  endDate,
}: {
  transactions: JsonTransaction[];
  accountId?: string;
  token?: string;
  startDate: string;
  endDate: string;
}) => {
  const mStartDate = dayjs(startDate).startOf('day');
  const mEndDate = dayjs(endDate).endOf('day');

  return filter(transactions, (transaction) => {
    // skip automatic transactions
    if (transaction.auto) {
      return false;
    }

    // skip if transaction is not for the target account
    if (accountId && transaction.accountID !== accountId) {
      return false;
    }

    // // skip if transaction is not for the target token
    if (token && transaction.currency?.toLowerCase() !== token?.toLowerCase()) {
      return false;
    }

    // skip if transaction is not in the range
    const dateFormat = transaction.auto ? 'YYYY-MM-DD HH:mm' : 'YYYY-MM-DD HH:mm';
    const mTransaction = dayjs(transaction.date, dateFormat);

    return !(mTransaction.isAfter(mEndDate) || mTransaction.isBefore(mStartDate));
  });
};

const getNewComparisonData = (
  HistoryItems: JsonBalanceHistoryItem[],
  isInitialBalanceUsed: boolean,
  transactions: JsonTransaction[] = [],
  comparisonStartDate?: string,
  comparisonEndDate?: string,
  isUseTransitionsDiff = false,
) => {
  // ex. 2024-06-11 00:00 for midnight
  const startDate = comparisonStartDate;

  const historySortedByDate = sortBy(HistoryItems, ['date']);

  const startItem = find(
    historySortedByDate,
    ({ date }) =>
      // this condition is needed to limit the end date to the exact day,
      // remove this condition if we want to fallback to the closest next balance
      dayjs(date).isSameOrBefore(dayjs(startDate).endOf('day').add(-1, 'minute')) &&
      dayjs(date).isSameOrAfter(dayjs(startDate).add(-1, 'minute')),
  );

  // ex. 2024-06-11 00:00 for midnight
  // ex. 2024-06-11 23:59
  const endDate = comparisonEndDate;

  const endItem = historySortedByDate.findLast(
    ({ date }) =>
      // this condition is needed to limit the end date to the exact day,
      // remove this condition if we want to have a fallback to the closest previous balance
      dayjs(date).isSameOrAfter(dayjs(endDate).startOf('day').add(-1, 'minute')) &&
      dayjs(date).isSameOrBefore(endDate),
  );

  const transactionsDiff = reduce(
    transactions,
    (res, transaction) =>
      // return res;
      ({
        volume: res.volume + transaction.volume,
        volumeUSD: res.volumeUSD + transaction.usd,
      }),
    { volume: 0, volumeUSD: 0 },
  );

  const startData = getNewHistoryDataItem({
    item: startItem,
    isInitialBalanceUsed,
    transactionsDiff: isUseTransitionsDiff ? undefined : transactionsDiff,
  });
  const endData = getNewHistoryDataItem({
    item: endItem,
    // we should never use initial balance for the end date in comparison
    isInitialBalanceUsed: false,
  });

  const strEndValue = formatNumber(endData.volume, { ...defaultFormat, currency: endItem?.token });
  const strStartValue = formatNumber(startData.volume, {
    ...defaultFormat,
    currency: startItem?.token,
  });
  const strVolumeHint = `${endData.volumeHint ?? strEndValue} - (${strStartValue})`;

  const strEndValueUSD = formatNumber(endData.volumeUSD, { ...defaultFormat, currency: 'USD' });
  const strStartValueUSD = formatNumber(startData.volumeUSD, { ...defaultFormat, currency: 'USD' });
  const strVolumeUSDHint = `${endData.volumeUSDHint ?? strEndValueUSD} - (${strStartValueUSD})`;

  const diff: HistoryDataItem = {
    price: 0,
    isSmallBalance: false,
    volume: isUseTransitionsDiff
      ? (endData?.volume ?? 0) - ((startData?.volume ?? 0) + transactionsDiff.volume)
      : (endData?.volume ?? 0) - (startData?.volume ?? 0),
    volumeUSD: isUseTransitionsDiff
      ? (endData?.volumeUSD ?? 0) - ((startData?.volumeUSD ?? 0) + transactionsDiff.volumeUSD)
      : (endData?.volumeUSD ?? 0) - (startData?.volumeUSD ?? 0),
    volumeHint: strVolumeHint,
    volumeUSDHint: strVolumeUSDHint,
  };

  return {
    startDate,
    startData,
    endDate,
    endData,
    startHistoryItem: startItem,
    endHistoryItem: endItem,
    diff,
    isSmallBalance: (startData?.isSmallBalance && endData?.isSmallBalance) ?? false,
  } as NewComparisonItem;
};

const getHistoryWithTransactions = (
  historyItems: JsonBalanceHistoryItem[],
  transactions: JsonTransaction[] = [],
) => {
  const historyWithTransactionsMap: Record<string, JsonBalanceHistoryItem> = {};

  // const update history with transactions
  each(historyItems, (item) => {
    const date = dayjs(item?.date).format('YYYY-MM-DD');
    const transaction = find(transactions, (t) => t.date === item?.date);

    if (transaction) {
      historyWithTransactionsMap[date] = {
        ...item,
        historyAmount: item.historyAmount + (transaction.volume ?? 0),
      };
    } else {
      historyWithTransactionsMap[date] = item;
    }
  });

  // convert other transactions to history items
  each(transactions, (transaction) => {
    const date = dayjs(transaction?.date).format('YYYY-MM-DD');

    if (!historyWithTransactionsMap[date]) {
      historyWithTransactionsMap[date] = {
        accountID: transaction.accountID,
        date: transaction.date,
        type: /USDT?/.test(transaction.currency) ? 0 : 1,
        token: transaction.currency,
        price: transaction.usd / transaction.volume,
        quotePrice: transaction.usd / transaction.volume,
        historyAmount: transaction.volume,
        initialAmount: Number.MIN_SAFE_INTEGER,
      };
    }
  });

  return values(historyWithTransactionsMap);
};

function getFutureQuoteVolume({
  baseAsset = {
    leverage: 1,
    volume: 0,
    volumeUSD: 0,
    token: '',
    lastPrice: 0,
    type: 1,
  },
  quoteAsset,
}: {
  baseAsset?: JsonBalanceAsset;
  quoteAsset: JsonBalanceAsset;
}) {
  const absBaseVal = Math.abs(baseAsset.volumeUSD);
  const absQuoteVal = Math.abs(quoteAsset.volumeUSD);
  const leverage = baseAsset.leverage ?? 1;
  const value = absBaseVal / leverage + absQuoteVal;
  const opts = { ...defaultFormat, currency: 'USD' };
  const strBaseVal = formatNumber(absBaseVal, opts);
  const strQuoteVal = formatNumber(absQuoteVal, opts);
  const tooltip = `${strBaseVal} / ${leverage} leverage + ${strQuoteVal}`;
  return { value, tooltip };
}

function getTotal(
  assets: JsonBalanceAsset[],
  key: 'volume' | 'volumeUSD',
  opts: {
    isTotalDoubling?: boolean;
    isSmallBalancesVisible?: boolean;
    isFuture?: boolean;
    domain: ApplicationDomain;
  },
) {
  const hintParts: string[] = [];

  const value = reduce(
    assets,
    (sum, asset) => {
      if (!opts.isTotalDoubling && asset.isDoubling) {
        return sum;
      }

      if (!opts.isSmallBalancesVisible && asset.isSmallBalance) {
        return sum;
      }

      const formatOpts = { ...defaultFormat, currency: key === 'volumeUSD' ? 'USD' : asset.token };

      if (opts.isFuture) {
        // if (opts.domain !== ApplicationDomain.Retainers) {
        //   const absVal = Math.abs(asset[key]);
        //   hintParts.push(
        //     `(${formatNumber(absVal, key === 'volumeUSD' ? { style: 'currency', currency: 'USD' } : {})} / ${asset.leverage} leverage)`,
        //   );
        //   return sum + absVal / asset.leverage;
        // }

        if ([0, 'quote'].includes(asset.type)) {
          const absVal = Math.abs(asset[key]);
          hintParts.push(`${formatNumber(absVal, formatOpts)}`);
          return sum + absVal;
        }

        return sum;
      }

      hintParts.push(`${formatNumber(asset[key], formatOpts)}`);
      return Math.abs(sum + asset[key]);
    },
    0,
  );

  return {
    value,
    tooltip: hintParts.join(' + '),
  };
}

const getSpotAdjustment = (
  spotData: Omit<HistoryDataItem, 'price' | 'isSmallBalance'> | null,
  futureData?: Omit<HistoryDataItem, 'price' | 'isSmallBalance'> | null,
  type?: string,
) => {
  let { volume = 0, volumeUSD = 0, volumeHint = ``, volumeUSDHint = `` } = spotData ?? {};

  const formatUsd = { ...defaultFormat, currency: 'USD' };
  const formatToken = { ...defaultFormat, currency: 'token' };

  if (type === 'base') {
    volumeHint = `${volumeHint || formatNumber(volume, formatToken)} + (${formatNumber(futureData?.volume ?? 0, formatToken)} futures)`;
    volumeUSDHint = `${volumeUSDHint || formatNumber(volumeUSD, formatUsd)} + (${formatNumber(futureData?.volumeUSD ?? 0, formatUsd)} futures)`;
    volume += futureData?.volume ?? 0;
    volumeUSD += futureData?.volumeUSD ?? 0;
  } else if (type === 'quote') {
    volumeHint = `${volumeHint || formatNumber(volume, formatToken)} - (${formatNumber(futureData?.volumeUSD ?? 0, formatUsd)} futures)`;
    volumeUSDHint = `${volumeUSDHint || formatNumber(volumeUSD, formatUsd)} - (${formatNumber(futureData?.volumeUSD ?? 0, formatUsd)} futures)`;
    volume -= futureData?.volumeUSD ?? 0;
    volumeUSD -= futureData?.volumeUSD ?? 0;
  }

  return {
    volume,
    volumeUSD,
    volumeHint,
    volumeUSDHint,
  };
};

interface GetDoublingMapTypes {
  balances: JsonBalance[];
  accounts?: JsonConnection[];
  tokenMap: Record<string, string>;
}

export const getDoublingMap = ({ balances = [], accounts = [], tokenMap }: GetDoublingMapTypes) => {
  const resultMap: Record<JsonBalance['accountID'], boolean> = {};

  if (!balances?.length || !accounts?.length) {
    return resultMap;
  }

  const tempMap: Record<number, Record<string, JsonBalanceAsset>> = {};
  const tempMapAll: Record<number, boolean> = {};

  map(balances, (balance) => {
    const isRebalancing = reRebalancing.test(`${balance.accountName}`);
    if (isRebalancing) {
      return;
    }

    const account = find(accounts, { name: balance.accountID });

    if (!account) {
      return;
    }

    const dblKey = account.group!;
    if (dblKey && !tempMap[dblKey]) {
      tempMap[dblKey] = {};
    }

    // const assets =
    map(balance.assets, (asset) => {
      const token = getToken(asset.token, tokenMap);
      const isDoubling = (tempMap[dblKey]?.[token]?.volume ?? 0) === (asset.volume ?? 0);

      if (tempMap[dblKey] && !tempMap[dblKey]?.[token]) {
        tempMap[dblKey][token] = asset;
      }

      // we don't want to have partial doubles
      // if (isDoubling) {
      //   resultMap[`${balance.accountID}__${token}`] = isDoubling;
      // }

      return isDoubling;
    });

    // we don't use custom check for group, we use server side for duplicates
    if (get(tempMapAll, dblKey)) {
      resultMap[balance.accountID] = true;
    }

    tempMapAll[dblKey] = true;

    // const isDoublingAll = every(assets)
    // let accountGroup = /(\w+)$/.exec(account?.label ?? '')?.[1] ?? '';
    // console.log({ A: account?.label, accountGroup });
    // if (accountGroup === 'test') {
    //   accountGroup = 'A1';
    // }

    // if (!tempMapAll[dblKey]) {
    //   tempMapAll[dblKey] = {};
    // }
  });

  return resultMap;
};

type FutureMapType = Record<string, string>;

interface GetFilteredBalancesData {
  isDla?: boolean;
  isTotalDoubling?: boolean;
  isComparisonSmallBalance?: boolean;
  isTotalSmallBalance?: boolean;
  isRebalancing?: boolean;
}

interface GetFilteredBalancesProps<T> {
  data: T[];
  isSmallBalancesVisible?: boolean;
  isDuplicatesVisible?: boolean;
  showDlaBots?: boolean;
}

const getFilteredBalances = <T extends GetFilteredBalancesData>({
  data = [],
  isSmallBalancesVisible = false,
  isDuplicatesVisible = true,
  showDlaBots = true,
}: GetFilteredBalancesProps<T>) =>
  reject(data, (balance) => {
    if (!showDlaBots && balance.isDla) {
      return true;
    }

    if (!isDuplicatesVisible && !!balance.isTotalDoubling) {
      return true;
    }

    if (!isSmallBalancesVisible) {
      if (!!balance.isTotalSmallBalance || !!balance.isComparisonSmallBalance) {
        return true;
      }
    }

    // SF account should be hidden
    return !!balance.isRebalancing;
  });

const getIsSmallBalance = (asset: JsonBalanceAsset) => {
  if (asset.lastPrice === 0) {
    return false;
  }

  const volumeUSD = asset.volume * asset.lastPrice;
  return Math.abs(volumeUSD) < smallTokensThreshold;
};

const getFutureMap = ({ balances = [] }: { balances: JsonBalance[] }) => {
  const futureMap: FutureMapType = {};
  const accountKeys = map(balances, 'accountID');

  // const futureKeys =
  map(accountKeys, (key) => {
    const balance = find(balances, { accountID: key });
    const name = balance?.accountName ?? '';
    const isSpot = reSpot.test(name);

    if (isSpot) {
      const futureKey = reSpot.exec(name)?.[1];
      futureMap[key] = futureKey!;
      return futureKey;
    }

    return null;
  });

  // const spotKeys =
  map(accountKeys, (key) => {
    const balance = find(balances, { accountID: key });
    const name = balance?.accountName ?? '';
    const isFuture = reFuture.test(name);

    if (isFuture) {
      const futureKey = reFuture.exec(name)?.[1];
      each(futureMap, (future, spotKey: string) => {
        if (future === futureKey) {
          futureMap[spotKey] = key;
        }
      });
      return futureKey;
    }

    return null;
  });

  return futureMap;
};

export const getTargetCurrency = ({ targetKey, token }: { targetKey: string; token: string }) =>
  targetKey === 'volumeUSD' || ['USD', 'USDT'].includes(token) ? 'USD' : token;

const getSummaryDiffValue = (
  assets: Pick<JsonBalanceHistoryAsset, 'type' | 'comparison' | 'token'>[],
  key: 'volume' | 'volumeUSD',
  type: 'base' | 'quote',
) =>
  filter(assets, { type }).reduce((sum, asset) => {
    let diff = get(asset?.comparison?.diff, key) ?? 0;
    if (type === 'quote' && asset.token !== 'USDT') {
      diff = get(asset?.comparison?.diff, 'volumeUSD') ?? 0; // if token is not USDT, we should use volumeUSD
    }
    return sum + diff;
  }, 0);

export const getAvgPerAccount = (
  assets: Pick<JsonBalanceHistoryAsset, 'type' | 'comparison' | 'token'>[],
  key: 'volume' | 'volumeUSD',
) => {
  const foundBaseAsset = find(assets, { type: 'base' });

  const quoteDiffValue = getSummaryDiffValue(assets, key, 'quote');

  const baseDiffValue = getSummaryDiffValue(assets, key, 'base');

  let avgValue = 0;
  let tooltip = '';

  if (baseDiffValue) {
    avgValue = (quoteDiffValue ?? 0) / baseDiffValue;
    const formatUsd = { ...defaultFormat, currency: 'USD' };
    const formatToken = { ...defaultFormat, currency: foundBaseAsset?.token };
    const strQuoteDiff = formatNumber(quoteDiffValue ?? 0, formatUsd);
    const strBaseDiff = formatNumber(baseDiffValue, formatToken);
    const strAvg = formatNumber(avgValue, formatUsd);
    tooltip = `${strQuoteDiff} / ${strBaseDiff} = ${strAvg}`;
  } else {
    avgValue = Infinity;
    tooltip = 'N/A';
  }

  return {
    value: avgValue,
    tooltip,
  };
};

const getSummaryTokenValue = (
  assets: Pick<JsonBalanceHistoryAsset, 'type' | 'comparison' | 'token'>[],
  type: 'quote' | 'base',
  format: 'compactLong' | 'precision' = 'precision',
  priceBaseToken?: number,
) => {
  const formatUsd = { format, currency: 'USD' };
  return filter(assets, { type }).reduce(
    (acc, val) => {
      const price =
        priceBaseToken ?? get(val, 'lastPrice') ?? get(val.comparison.endData, 'price') ?? 0;
      const diffVolume = get(val.comparison.diff, 'volume') ?? 0;

      let result = price * diffVolume;

      let strResult = `${formatNumber(price, formatUsd)} * ${formatNumber(diffVolume, { format: 'precision' })}`;

      if (!price || price === 1) {
        strResult = `${formatNumber(get(val.comparison.diff, 'volumeUSD') ?? 0, formatUsd)}`;
        result = get(val.comparison.diff, 'volumeUSD') ?? 0;
      }

      if (!result) {
        return acc;
      }

      return {
        value: acc.value + result,
        tooltip: acc.tooltip ? `${acc.tooltip} + ${strResult}` : strResult,
      };
    },
    {
      value: 0,
      tooltip: '',
    },
  );
};

export const getPnlPerAccount = (
  assets: Pick<JsonBalanceHistoryAsset, 'type' | 'comparison' | 'token'>[],
  currentPrice: number,
  numberFormat: 'compactLong' | 'precision' = 'precision',
) => {
  const quoteDiff = getSummaryTokenValue(assets, 'quote', numberFormat);
  const baseDiff = getSummaryTokenValue(assets, 'base', numberFormat, currentPrice);
  return reduce(
    [quoteDiff, baseDiff],
    (acc, val) => {
      if (!val.value) {
        return acc;
      }

      return {
        value: acc.value + val.value,
        tooltip: acc.tooltip ? `${acc.tooltip} + ${val.tooltip}` : val.tooltip,
      };
    },
    { value: 0, tooltip: '' },
  );
};

interface GetExtendedBalancesTypes {
  domain: ApplicationDomain;
  isSmallBalancesVisible?: boolean;
  isDuplicatesVisible?: boolean;
  balances?: JsonBalance[];
  coldWallets?: JsonBalance[];
  exchanges: JsonExchange[];
  accounts: JsonConnection[];
  brokenAccounts?: JsonConnection[];
  showDlaBots?: boolean;
  selectedTokens?: UniqueTokensList[];
  tokenMap: Record<string, string> | undefined;
}

export const getNewExtendedBalances = ({
  domain,
  isSmallBalancesVisible = false,
  isDuplicatesVisible = true,
  balances = [],
  coldWallets = [],
  exchanges = [],
  accounts = [],
  brokenAccounts = [],
  showDlaBots = true,
  selectedTokens = [],
  tokenMap = {},
}: GetExtendedBalancesTypes): JsonBalanceExtended[] => {
  const allAccounts = [...accounts, ...brokenAccounts];

  if (!coldWallets?.length && !balances?.length) {
    return [];
  }

  const combinedBalances = [...balances, ...coldWallets];

  if (!combinedBalances.length || !exchanges?.length || !allAccounts?.length) {
    return [];
  }

  const tokens = map(selectedTokens, 'token');

  // DLA > MM > OTHER (if DLA are shown)
  // MM > OTHER > DLA (if DLA are hidden)
  const sortBalances = sortBy(combinedBalances, (item) => showDlaBots && !isDla(item.accountName));

  const newMap = getDoublingMap({ balances: sortBalances, accounts: allAccounts, tokenMap });
  const futureMap = getFutureMap({ balances: combinedBalances });

  const result = map(combinedBalances, (balance, idx, list) => {
    const account = find(allAccounts, { name: balance.accountID });

    const isWallet = isWalletAccount(balance.accountLabel);

    const isFuture = reFuture.test(`${balance.accountName}`);
    const isSpot = reSpot.test(`${balance.accountName}`);
    const isRebalancing = reRebalancing.test(`${balance.accountName}`);
    const assetsSelectedTokens = filter(balance.assets, (asset) =>
      tokens.includes(getToken(asset.token, tokenMap)),
    );

    const assets = map(assetsSelectedTokens, (asset) => {
      const token = getToken(asset.token, tokenMap);

      // we don't want to have partial doubles
      const isDoubling = !balance.accountLabel.toLowerCase().includes('wallet')
        ? newMap[balance.accountID]
        : false;

      return {
        ...asset,
        token,
        isSmallBalance: getIsSmallBalance(asset),
        volume: asset.volume,
        volumeUSD: asset.volume * asset.lastPrice,
        isDoubling,
      };
    });

    const piechartData = map(assets, (asset) => {
      const allAssetsSum = reduce(assets, (sum, item) => sum + item.volumeUSD, 0);
      return {
        id: asset.token,
        label: asset.token,
        value: asset.volumeUSD,
        percentageValue: asset.volumeUSD / allAssetsSum,
        color:
          asset.type === 1
            ? getPieColor(BASE_TOKEN_COLOR_NUMBER)
            : getPieColor(STABLES_COLOR_NUMBER),
      };
    });

    const isTotalDoubling = !isWallet ? newMap[balance.accountID] : false;

    const exchange = find(exchanges, { id: balance.exchangeID });

    const baseItem = find(assets, { type: 1 });
    const baseToken = baseItem ? getToken(baseItem.token, tokenMap) : undefined;
    const quoteItem = find(assets, { type: 0 });
    const quoteToken = quoteItem ? getToken(quoteItem.token, tokenMap) : undefined;

    const { value: totalVolumeUSD, tooltip: totalVolumeTooltip } = getTotal(assets, 'volumeUSD', {
      isTotalDoubling,
      isSmallBalancesVisible,
      isFuture,
      domain,
    });

    return {
      ...balance,
      key: balance.accountID,
      assets,
      baseToken: toUpper(baseToken),
      quoteToken: toUpper(quoteToken),
      piechartData,

      isTotalDoubling,
      isDla: isDla(balance.accountName),
      totalVolumeUSD,
      totalVolumeTooltip,
      isTotalSmallBalance:
        account?.status.valueOf() !== -2 && assets.length > 0 && every(assets, 'isSmallBalance'),
      exchange,
      account,
      hue: getLineHue(idx, list.length),
      groupMap: getGroupMap({ exchange, account, isWallet }),

      // futures
      isSpot,
      isFuture,
      isRebalancing,
      futureId: futureMap[balance.accountID],
      updated: balance.updated,
    } as JsonBalanceExtended;
  });

  const resultsWithFutures = map(result, (balance, idx, list) => {
    // adjust volumes for spot account
    if (balance.futureId) {
      const future = find(list, { key: balance.futureId });

      if (balance.isSpot && future) {
        const futureBaseAsset = find(future?.assets, { token: balance.baseToken });

        const assets = map(balance.assets, (asset) => ({
          ...asset,
          ...getSpotAdjustment(asset, futureBaseAsset, asset.type === 1 ? 'base' : 'quote'),
        }));

        const { value: totalVolumeUSD, tooltip: totalVolumeTooltip } = getTotal(
          assets,
          'volumeUSD',
          {
            isTotalDoubling: balance.isTotalDoubling,
            isSmallBalancesVisible,
            isFuture: balance.isFuture,
            domain,
          },
        );

        return {
          ...balance,
          assets,
          totalVolumeUSD,
          totalVolumeTooltip,
          future,
        };
      }

      return {
        ...balance,
        future,
      };
    }

    // adjust quote volume for futures account to show total
    if (domain === ApplicationDomain.Retainers && balance.isFuture) {
      const baseAsset = find(balance.assets, (item) => [1, 'base'].includes(item?.type || ''));

      const assets = map(balance.assets, (asset) => {
        if ([0, 'quote'].includes(asset.type)) {
          const { value, tooltip } = getFutureQuoteVolume({
            baseAsset,
            quoteAsset: asset,
          });

          return {
            ...asset,
            volume: value,
            volumeUSD: value,
            tooltip,
            tooltipUSD: tooltip,
          };
        }

        return asset;
      });

      const { value: totalVolumeUSD, tooltip: totalVolumeTooltip } = getTotal(assets, 'volumeUSD', {
        isTotalDoubling: balance.isTotalDoubling,
        isSmallBalancesVisible,
        isFuture: balance.isFuture,
        domain,
      });

      return {
        ...balance,
        assets,
        totalVolumeUSD,
        totalVolumeTooltip,
      };
    }

    return balance;
  });

  return getFilteredBalances<(typeof resultsWithFutures)[number]>({
    data: resultsWithFutures,
    isSmallBalancesVisible,
    isDuplicatesVisible,
    showDlaBots,
  });
};

export interface GetExtendedBalancesHistoryTypes {
  domain: ApplicationDomain;
  isSmallBalancesVisible?: boolean;
  isDuplicatesVisible?: boolean;
  isInitialBalanceUsed?: boolean;
  comparisonStartDate?: string; // YYYY-MM-DD
  comparisonEndDate?: string; // YYYY-MM-DD
  balances?: JsonBalance[];
  history?: JsonBalanceHistoryItem[];
  coldWallets?: JsonBalance[];
  coldWalletsSnapshots?: JsonBalanceHistoryItem[];
  exchanges: JsonExchange[];
  accounts: JsonConnection[];
  brokenAccounts?: JsonConnection[];
  transactions?: JsonTransaction[];
  showDlaBots?: boolean;

  supportedTokens?: Omit<UniqueTokensList, 'isSmallBalance'>[];
  tokenMap?: Record<string, string>;
  isUseTransitionsDiff?: boolean; //this flag allows you to ignore transaction differences for startValue, but transaction results are still considered when calculating the difference.
}

export const getExtendedBalancesHistory = ({
  domain,
  isSmallBalancesVisible = false,
  isDuplicatesVisible = true,
  isInitialBalanceUsed = false,
  comparisonStartDate = undefined,
  comparisonEndDate = undefined,
  balances = [],
  history = [],
  coldWallets = [],
  coldWalletsSnapshots = [],
  exchanges = [],
  accounts = [],
  brokenAccounts = [],
  transactions = [],
  showDlaBots = true,
  supportedTokens = [],
  tokenMap = {},
  isUseTransitionsDiff = false,
}: GetExtendedBalancesHistoryTypes): JsonBalanceHistoryExtended[] => {
  const coldWalletAccounts = coldWallets.map((wallet) => wallet.account);
  const allAccounts = [...accounts, ...brokenAccounts, ...coldWalletAccounts];

  const combinedBalances = [...balances, ...coldWallets];
  const combinedHistory = [...history, ...coldWalletsSnapshots];

  if (
    !balances?.length ||
    !history?.length ||
    !exchanges?.length ||
    !allAccounts?.length ||
    !comparisonStartDate ||
    !comparisonEndDate
  ) {
    return [];
  }
  // DLA > MM > OTHER (if DLA are shown)
  // MM > OTHER > DLA (if DLA are hidden)
  const sortBalances = sortBy(combinedBalances, (item) => showDlaBots && !isDla(item.accountName));
  const filteredAccounts = allAccounts.filter(
    (account): account is JsonConnection => account !== undefined,
  );
  const newMap = getDoublingMap({ balances: sortBalances, accounts: filteredAccounts, tokenMap });
  const futureMap = getFutureMap({ balances: combinedBalances });

  const historyMap = groupBy(combinedHistory, 'accountID');
  const historyKeys = keys(historyMap);
  const supportTokenObj = keyBy(supportedTokens, 'token');

  const result = map(historyKeys, (key, idx, list) => {
    const historyItems = historyMap[key];
    const account = find(allAccounts, { name: key });
    let balance = find(combinedBalances, { accountID: key });
    const exchange = find(exchanges, { id: balance?.exchangeID });

    const isWallet = isWalletAccount(balance?.accountLabel);
    const tokensMap = groupBy(historyItems, (item) => {
      const token = getToken(item.token, tokenMap);
      return `${token}__${item.type}`;
    });

    const assets = map(tokensMap, (tokenHistoryItems, keyStr) => {
      const [token, type] = keyStr.split('__');

      // we don't want to have partial doubles
      // const isDoubling = newMap[`${key}__${token}`] ?? false;
      const isDoubling = !isWallet ? newMap[key] : false;

      const transactionsInRange = getTransactionsInRange({
        transactions,
        accountId: key,
        token,
        startDate: comparisonStartDate,
        endDate: comparisonEndDate,
      });

      const comparison = getNewComparisonData(
        tokenHistoryItems,
        isInitialBalanceUsed,
        transactionsInRange,
        comparisonStartDate,
        comparisonEndDate,
        isUseTransitionsDiff,
      );

      const asset = find(balance?.assets, { token });

      const tokenType = `${get(supportTokenObj, `${token}.type`, type) as string}`;

      return {
        // ...asset,
        token,
        lastPrice: asset?.lastPrice,
        leverage: asset?.leverage,
        // extras for comparison
        comparison,
        history: getHistoryWithTransactions(tokenHistoryItems, transactionsInRange),
        isDoubling,
        type: historyItemTypeMap[tokenType],
      };
    });
    const isTotalDoubling = !isWallet ? newMap[key] : false;
    const baseItem = find(assets, { type: 'base' });
    const quoteItem = find(assets, { type: 'quote' });

    if (!balance) {
      balance = {
        accountLabel: account?.label ?? '',
      } as JsonBalance;
    }

    return {
      ...balance,

      key,
      baseToken: baseItem ? getToken(baseItem.token, tokenMap) : undefined,
      quoteToken: quoteItem ? getToken(quoteItem.token, tokenMap) : undefined,
      assets,
      // history: historyItems, // for debug mostly
      isTotalDoubling,
      isDla: isDla(balance?.accountName ?? ''),
      // totalVolume: getTotal(assets, 'volume', {
      //   isTotalDoubling,
      //   isSmallBalancesVisible,
      // }),
      // totalVolumeUSD: getTotal(assets, 'volumeUSD', {
      //   isTotalDoubling,
      //   isSmallBalancesVisible,
      // }),
      // isTotalSmallBalance: every(assets, 'isSmallBalance'),
      isComparisonSmallBalance: every(
        assets,
        (asset) => !asset.comparison || asset.comparison.isSmallBalance,
      ),
      exchange,
      account,
      groupMap: getGroupMap({ exchange, account, isWallet }),
      hue: getLineHue(idx, list.length),

      // futures
      isSpot: reSpot.test(`${balance?.accountName}`),
      isFuture: reFuture.test(`${balance?.accountName}`),
      isRebalancing: reRebalancing.test(`${balance?.accountName}`),
      futureId: futureMap[key],
    } as JsonBalanceHistoryExtended;
  });

  const resultsWithFutures = map(result, (balance, idx, list) => {
    // adjust volumes for spot account
    if (balance.futureId) {
      const future = find(list, { key: balance.futureId });

      if (balance.isSpot && future) {
        const futureBaseAsset = find(future?.assets, { token: balance.baseToken });

        const assets = map(balance.assets, (asset) => ({
          ...asset,
          comparison: {
            ...asset.comparison,
            startData: {
              ...asset.comparison.startData!,
              ...getSpotAdjustment(
                asset.comparison.startData,
                futureBaseAsset?.comparison?.startData,
                asset.type,
              ),
            },
            endData: {
              ...asset.comparison.endData!,
              ...getSpotAdjustment(
                asset.comparison.endData,
                futureBaseAsset?.comparison?.endData,
                asset.type,
              ),
            },
            diff: {
              ...asset.comparison.diff!,
              ...getSpotAdjustment(
                asset.comparison.diff,
                futureBaseAsset?.comparison?.diff,
                asset.type,
              ),
            },
          },
        }));

        return {
          ...balance,
          assets,
          future,
        };
      }

      return {
        ...balance,
        future,
      };
    }

    // adjust quote volume for futures account to show total
    if (domain === ApplicationDomain.Retainers && balance.isFuture) {
      const baseAsset = find(balance.assets, (item) => [1, 'base'].includes(`${item?.type}`));

      const assets = map(balance.assets, (asset) => {
        if ([0, 'quote'].includes(`${asset.type}`)) {
          const { value: startVolume } = getFutureQuoteVolume({
            baseAsset: baseAsset
              ? {
                  ...baseAsset,
                  volume: baseAsset?.comparison?.startData?.volume ?? 0,
                  volumeUSD: baseAsset?.comparison?.startData?.volumeUSD ?? 0,
                  type: 0,
                }
              : undefined,
            quoteAsset: {
              ...asset,
              volume: asset?.comparison?.startData?.volume ?? 0,
              volumeUSD: asset?.comparison?.startData?.volumeUSD ?? 0,
              type: 1,
            },
          });

          const { value: endVolume } = getFutureQuoteVolume({
            baseAsset: baseAsset
              ? {
                  ...baseAsset,
                  volume: baseAsset?.comparison?.endData?.volume ?? 0,
                  volumeUSD: baseAsset?.comparison?.endData?.volumeUSD ?? 0,
                  type: 0,
                }
              : undefined,
            quoteAsset: {
              ...asset,
              volume: asset?.comparison?.endData?.volume ?? 0,
              volumeUSD: asset?.comparison?.endData?.volumeUSD ?? 0,
              type: 1,
            },
          });

          const { value: diffVolume } = getFutureQuoteVolume({
            baseAsset: baseAsset
              ? {
                  ...baseAsset,
                  volume: baseAsset?.comparison?.diff?.volume ?? 0,
                  volumeUSD: baseAsset?.comparison?.diff?.volumeUSD ?? 0,
                  type: 0,
                }
              : undefined,
            quoteAsset: {
              ...asset,
              volume: asset?.comparison?.diff?.volume ?? 0,
              volumeUSD: asset?.comparison?.diff?.volumeUSD ?? 0,
              type: 1,
            },
          });

          return {
            ...asset,
            // volume: value,
            // volumeUSD: value,
            // tooltip,
            // tooltipUSD: tooltip,

            comparison: {
              ...asset.comparison,
              startData: {
                ...asset.comparison.startData!,
                volume: startVolume,
                volumeUSD: startVolume,
              },
              endData: {
                ...asset.comparison.endData!,
                volume: endVolume,
                volumeUSD: endVolume,
              },
              diff: {
                ...asset.comparison.diff!,
                volume: diffVolume,
                volumeUSD: diffVolume,
              },
            },
          };
        }

        return asset;
      });

      return {
        ...balance,
        assets,
      };
    }

    return balance;
  });

  return getFilteredBalances<(typeof resultsWithFutures)[number]>({
    data: resultsWithFutures,
    isSmallBalancesVisible,
    isDuplicatesVisible,
    showDlaBots,
  });
};

export const getFilteredTokens = ({
  tokens = [],
  ignoreTokens = [],
  isSmallBalancesVisible = false,
}: {
  isSmallBalancesVisible?: boolean;
  tokens: UniqueTokensList[];
  ignoreTokens: string[];
}) =>
  reject(tokens, (item) => {
    if (!isSmallBalancesVisible) {
      if (item.isSmallBalance && !ignoreTokens.includes(item.token)) {
        return true;
      }

      return !item.isSmallBalance && ignoreTokens.includes(item.token);
    }

    return Boolean(ignoreTokens.length && ignoreTokens.includes(item.token));
  });

// 0 - quote, 1 - base, 2 - other
// but for sorting we need base to be in the first place, so we assign base = 0
const sortObjType: Record<string | number, number> = {
  0: 1,
  1: 0,
  2: 2,
  base: 0,
  quote: 1,
  other: 2,
};

export const getUniqueCompareTokens = ({
  data = [],
  isIgnoreOther = false,
  tokenMap = {},
}: {
  data?: JsonBalanceHistoryExtended[];
  isIgnoreOther?: boolean;
  tokenMap?: Record<string, string>;
}) => {
  const assetsTokens = flatMap(data, (item) => {
    const assets = filter(item.assets, (asset) => !!asset.comparison);

    return map(assets, (asset) => {
      return {
        isSmallBalance: asset.comparison.isSmallBalance,
        token: getToken(asset.token, tokenMap),
        type: sortObjType[asset.type],
      };
    });
  });

  const groupTokens = groupBy(assetsTokens, 'token');

  let tokens = map(groupTokens, (item, key) => {
    return {
      token: key,
      type: Math.min(...map(item, 'type')), // if the same token has different types: e.g. "quote" and "other", then we convert it to "quote"
      isSmallBalance: every(item, { isSmallBalance: true }),
    };
  });

  if (isIgnoreOther) {
    tokens = filter(tokens, (item) => item.type !== 2);
  }

  const sortTokens = flatMap(groupBy(tokens, 'type'), (item) =>
    item.sort((a, b) => a.token.localeCompare(b.token)),
  );

  return uniqBy(sortTokens, 'token');
};

export const getUniqueBalanceTokens = ({
  data = [],
  isIgnoreOtherTokens = false,
  supportedTokens = [],
  tokenMap = {},
}: {
  data?: JsonBalance[];
  isIgnoreOtherTokens?: boolean;
  supportedTokens?: UniqueTokensList[];
  tokenMap?: Record<string, string>;
}) => {
  const supportTokensOnBalance = map(supportedTokens, ({ token, type }) => ({
    token: getToken(token, tokenMap),
    type: sortObjType[type],
  }));

  const objSupportTokens = keyBy(supportedTokens, 'token');

  let tokens = flatMap(data, (item) => {
    return map(item.assets, (asset) => {
      let token = asset.token;
      let type = asset.type;

      if (objSupportTokens[token]) {
        token = objSupportTokens[token].token;
        type = objSupportTokens[token].type;
      }

      return {
        ...asset,
        token: getToken(token, tokenMap),
        type: sortObjType[type],
      };
    });
  });

  tokens = [...tokens, ...supportTokensOnBalance] as JsonBalanceAsset[];

  if (isIgnoreOtherTokens) {
    tokens = filter(tokens, (item) => item.type !== 2);
  }

  const infoTokens = getTokenInfo(tokens);

  return flatMap(groupBy(infoTokens, 'type'), (item) =>
    item.sort((a, b) => a.token.localeCompare(b.token)),
  );
};

export const getUniqueBalanceHistoryTokens = ({
  data = [],
  isSmallBalancesVisible = false,
  tokenMap = {},
}: {
  data?: JsonBalanceHistoryExtended[];
  isSmallBalancesVisible?: boolean;
  tokenMap?: Record<string, string>;
}) => {
  const tokens = flatMap(data, (item) => {
    const assets = isSmallBalancesVisible
      ? filter(item.assets, (asset) => !!asset.comparison)
      : filter(item.assets, (asset) => !!asset.comparison && !asset.comparison?.isSmallBalance);

    return map(assets, (asset) => getToken(asset.token, tokenMap));
  });

  return uniq(tokens).sort((a, b) => a.localeCompare(b));
};

interface GetTotalPerAssetSource {
  key: string;
  assets: (JsonBalanceHistoryAsset | JsonBalanceAsset)[];
  isRebalancing?: boolean;
  isFuture?: boolean;
  isDla?: boolean;
  isTotalDoubling?: boolean;
}

export const getTotalPerAsset = <T extends GetTotalPerAssetSource>({
  source,
  token,
  key,
  selection = [],
  isSmallBalancesVisible = false,
  ignoreDlaBots = false,
}: {
  source: T[];
  token: string;
  key:
    | 'volume'
    | 'volumeUSD'
    | 'comparison.startData.volumeUSD'
    | 'comparison.endData.volumeUSD'
    | 'comparison.startData.volume'
    | 'comparison.endData.volume'
    | 'comparison.diff.volume'
    | 'comparison.diff.volumeUSD';
  selection?: Key[];
  isSmallBalancesVisible?: boolean;
  ignoreDlaBots?: boolean;
}) =>
  reduce(
    source,
    (sum, item) => {
      if (selection?.length && !selection.includes(item.key)) {
        return sum;
      }

      if (ignoreDlaBots && item.isDla) {
        return sum;
      }

      const foundAsset = token ? find(item.assets, { token }) : undefined;

      if (item?.isRebalancing) {
        return sum;
      }

      if (item?.isTotalDoubling || foundAsset?.isDoubling) {
        return sum;
      }

      if (!isSmallBalancesVisible && foundAsset?.isSmallBalance) {
        return sum;
      }

      if (item?.isFuture && foundAsset?.type && [1, 'base'].includes(foundAsset?.type)) {
        return sum;
      }

      return sum + (get(foundAsset ?? item, key, 0) as number);
    },
    0,
  );

const getSortedBalanceAccountByGroup = <T extends { group: string }>(data: T[]) =>
  data.sort((a, b) => {
    if (a.group === 'Other') return 1;
    if (b.group === 'Other') return -1;
    return a.group.localeCompare(b.group);
  });

export const getCompareList = ({
  data = [],
  viewGroupBy = 'type',
}: {
  data: JsonBalanceHistoryExtended[];
  viewGroupBy: 'type' | 'exchange';
}) => {
  const result: JsonBalanceHistoryExtendedList[] = [];
  const groupedData = groupBy(data, `groupMap[${viewGroupBy}]`);
  each(groupedData, (i, group) => {
    result.push({
      key: `group:${group}`,
      label: group,
      assets: [] as JsonBalanceHistoryAsset[],
      group,
      groupAccountsId: map(i, (el) => el.accountID || el.key),
    } as JsonBalanceHistoryExtendedList);

    result.push(
      ...(map(i, (item: JsonBalanceHistoryExtended) => ({
        ...item,
        group,
      })) as JsonBalanceHistoryExtendedList[]),
    );
  });

  return getSortedBalanceAccountByGroup(result);
};

interface GetPricePerAssetSource {
  key: string;
  assets: JsonBalanceHistoryAsset[] | JsonBalanceAsset[];
  isRebalancing?: boolean;
  isFuture?: boolean;
  isDla?: boolean;
  isTotalDoubling?: boolean;
}

export const getPricePerAsset = <T extends GetPricePerAssetSource>({
  source,
  token,
  key,
  selection = [],
  isSmallBalancesVisible = false,
  ignoreDlaBots = false,
}: {
  key: 'lastPrice' | 'comparison.startData.price' | 'comparison.endData.price';
  source: T[];
  token: string;
  selection?: Key[];
  isSmallBalancesVisible?: boolean;
  ignoreDlaBots?: boolean;
}): number => {
  const arrayOfPrice = reduce(
    source,
    (sum, item) => {
      if (selection?.length && !selection.includes(item.key)) {
        return sum;
      }

      if (ignoreDlaBots && item.isDla) {
        return sum;
      }

      const foundAsset = token ? item.assets.find((asset) => asset.token === token) : undefined;

      if (!foundAsset) {
        return sum;
      }

      if (item?.isRebalancing) {
        return sum;
      }

      if (item?.isTotalDoubling || foundAsset?.isDoubling) {
        return sum;
      }

      if (!isSmallBalancesVisible && get(foundAsset ?? item, 'isSmallBalance')) {
        return sum;
      }

      if (item?.isFuture && [1, 'base'].includes((foundAsset ?? item)?.type)) {
        return sum;
      }

      const lastPrice = get(foundAsset ?? item, key) ?? 0;

      if (!lastPrice) {
        return [];
      }

      return [...sum, lastPrice];
    },
    [] as number[],
  );

  return mean(arrayOfPrice);
};

export const getTotalValue = (items: unknown, ignoreDlaBots = false, selection: Key[] = []) =>
  reduce<JsonBalanceExtended, number>(
    items as JsonBalanceExtended[],
    (sum, item) => {
      if (selection.length && !selection.includes(item.key)) {
        return sum;
      }

      if ((ignoreDlaBots && item.isDla) || item.isTotalDoubling || item.isRebalancing) {
        return sum;
      }

      return sum + (item.totalVolumeUSD ?? 0);
    },
    0,
  );

export const getTotalTooltip = (
  items: JsonBalanceExtended[],
  ignoreDlaBots = false,
  selection: Key[] = [],
) => {
  const totalTooltipValue = reduce(
    items,
    (sum, item) => {
      if (selection.length && !selection.includes(item.key)) {
        return sum;
      }

      if (ignoreDlaBots && item.isDla) {
        return sum;
      }

      if (!item.totalVolumeUSD) {
        return sum;
      }

      if (item?.isTotalDoubling) {
        return sum;
      }

      const value = formatNumber(item.totalVolumeUSD, { ...defaultFormat, currency: 'USD' });

      sum.push(value);

      return sum;
    },
    [] as string[],
  );
  return totalTooltipValue.join(' + ');
};

export const getBalanceList = ({
  data = [],
  viewGroupBy = 'type',
  showError = true,
}: {
  data: JsonBalanceExtended[];
  viewGroupBy: 'type' | 'exchange';
  showError?: boolean;
}) => {
  const result: JsonBalanceExtendedList[] = [];

  const filterData = showError ? data : filter(data, 'assets.length');
  const groupedData = groupBy(filterData, `groupMap[${viewGroupBy}]`);

  each(groupedData, (i, group) => {
    result.push({
      key: `group:${group}`,
      label: group,
      assets: [] as JsonBalanceAsset[],
      isTotalDoubling: false,
      group,
      groupAccountsId: map(i, (el) => el.accountID || el.key),
    } as JsonBalanceExtendedList);

    result.push(
      ...map(
        i,
        (item: JsonBalanceExtended) =>
          ({
            ...item,
            label: item.accountName || item.accountLabel,
            accountName: item.accountName || '',
            key: item.accountID,
            group,
          }) as JsonBalanceExtendedList,
      ),
    );
  });

  return getSortedBalanceAccountByGroup(result);
};

export function getBalanceTreeData<T>({
  data = [],
  uniqTokens = [],
  viewGroupBy = 'type',
  showSubtotals = false,
  isSmallBalancesVisible = false,
  ignoreDlaBots = false,
  selection = [],
  showError,
}: {
  data: JsonBalanceExtended[] | JsonBalanceHistoryExtended[];
  viewGroupBy?: BalanceViewGroupBy;
  showSubtotals?: boolean;
  uniqTokens?: string[];
  isSmallBalancesVisible?: boolean;
  ignoreDlaBots?: boolean;
  selection?: Key[];
  showError?: boolean;
}) {
  const filteredData = showError ? data : filter(data, 'assets.length');
  const groupedData = groupBy(filteredData, `groupMap[${viewGroupBy}]`) as Record<
    string,
    typeof data
  >;

  const balanceTreeData = map(groupedData, (children, group) => {
    const { groupMap = undefined } =
      find(children, ({ key }: (typeof data)[number]) => selection.includes(key)) ?? {};

    return {
      key: `group:${group}`,
      label: group,
      group,
      assets: [],
      children: !showSubtotals
        ? (children as T[])
        : [
            ...(children as T[]),
            {
              key: `subtotal:${group}`,
              label: 'Subtotal',
              assets: map(uniqTokens, (token) => ({
                token,
                lastPrice: getPricePerAsset<JsonBalanceExtended | JsonBalanceHistoryExtended>({
                  key: 'lastPrice',
                  source: children,
                  token,
                  selection: groupMap ? selection : [],
                  isSmallBalancesVisible,
                  ignoreDlaBots,
                }),
                volume: getTotalPerAsset<JsonBalanceExtended | JsonBalanceHistoryExtended>({
                  source: children,
                  token,
                  selection: groupMap ? selection : [],
                  key: 'volume',
                  isSmallBalancesVisible,
                  ignoreDlaBots,
                }),
                volumeUSD: getTotalPerAsset<JsonBalanceExtended | JsonBalanceHistoryExtended>({
                  source: children,
                  token,
                  selection: groupMap ? selection : [],
                  key: 'volumeUSD',
                  isSmallBalancesVisible,
                  ignoreDlaBots,
                }),
              })),
              totalVolumeUSD: getTotalValue(children, ignoreDlaBots, groupMap ? selection : []),
              totalVolumeTooltip: getTotalTooltip(
                children as JsonBalanceExtended[],
                ignoreDlaBots,
                groupMap ? selection : [],
              ),
            } as T,
          ],
    };
  });

  return getSortedBalanceAccountByGroup(balanceTreeData);
}

export const findHistoryPrices = (
  history: JsonBalanceHistoryItem[],
  comparisonEndDate: string | undefined,
  selection: string[] = [],
) => {
  const filteredHistory = filter(history, (item) => {
    const isSmallBalance = getIsSmallBalance({
      ...item,
      lastPrice: item.price,
      volume: item.historyAmount,
      volumeUSD: item.historyAmount * item.price,
      leverage: 1,
    });

    if (selection.length && !selection.includes(item.accountID)) {
      return false;
    }

    // skip non base tokens and future dates (for midnight snapshot)
    return !isSmallBalance && item.type === 1 && dayjs(item.date).isSameOrBefore(comparisonEndDate);
  });

  // here might be different tokens to handle

  const historyMapPerToken = groupBy(filteredHistory, 'token');

  return mapValues(historyMapPerToken, (tokenHistory) => {
    const tokenHistoryMapPerDate = groupBy(tokenHistory, 'date');
    const tokenHistoryMap = map(tokenHistoryMapPerDate, (dateHistory, date) => {
      const tokenPrice = reduce(dateHistory, (sum, item) => sum + item.price, 0);
      const tokenQty = dateHistory.length;

      return {
        date,
        price: tokenPrice / tokenQty,
      };
    });

    return tokenHistoryMap;
  });
};

export const getHistoryPrices = (
  history: JsonBalanceHistoryItem[],
  comparisonEndDate: string | undefined,
  selection: string[] | undefined = [],
  priceHistory: Record<string, PriceHistoryItem[]> | undefined = {},
): Record<string, PriceHistoryItemType[]> => {
  let historyMap: Record<string, PriceHistoryItemType[]> = {};
  if (!isEmpty(priceHistory)) {
    each(priceHistory, (items, date) => {
      each(items, (item) => {
        if (!historyMap[item.token]) {
          historyMap[item.token] = [];
        }
        historyMap[item.token].push({
          price: item.price,
          date: dayjs(date).format('YYYY-MM-DD HH:mm'),
        });
      });
    });
  }

  //are used as a backup
  const historyPrices = findHistoryPrices(history, comparisonEndDate, selection);
  historyMap = { ...historyPrices, ...historyMap };

  return mapValues(historyMap, (info) => sortBy(info, 'date'));
};

export const getFallbackHistoryPrices = (array: JsonBalanceHistoryExtended[]) => {
  let result = 0;
  each(array, (item) => {
    const { lastPrice = 0 } = find(item.assets, { type: historyItemTypeMap[1] }) ?? {};
    result = lastPrice;
    return !result;
  });
  return result;
};

export const getTokenOpts = (
  assets: JsonBalanceAsset[] | JsonBalanceHistoryAsset[],
  accountTokens: string[] = [],
): TokenOption[] => {
  const assetsToken = map(assets as JsonBalanceAsset[], (asset: JsonBalanceAsset) => asset?.token);

  return map(uniq([...assetsToken, ...accountTokens]), (token: string) => ({
    label: token,
    value: token,
  }));
};

const getCoordinates = (startDate?: string, endDate?: string, history?: string) => {
  const coordinates = [];
  let mCurrentDate = dayjs.utc(startDate);
  const mEndDate = dayjs.utc(endDate);

  while (mCurrentDate.isBefore(mEndDate) || mCurrentDate.isSame(mEndDate, 'day')) {
    coordinates.push(mCurrentDate.add(-1, 'minutes').local().format('YYYY-MM-DD HH:mm'));
    mCurrentDate = mCurrentDate.clone().add(1, 'days');
  }
  if (history) {
    const lastHistoryDate = dayjs.utc(history);
    if (!coordinates.includes(lastHistoryDate.local().format('YYYY-MM-DD HH:mm'))) {
      coordinates.push(lastHistoryDate.local().format('YYYY-MM-DD HH:mm'));
    }
  }

  return uniq(coordinates).map((date) => ({ x: date }));
};

const getTotalObj = (
  name: 'Total' | 'Subtotal' | 'Summary',
  token: string,
  data: { x: string; y: number }[],
  viewTarget: string,
  lineObj: LineColorOptsType,
  tokenType: 'Base' | 'Quote',
  currency: string | undefined = undefined,
) => ({
  id: JSON.stringify({
    id: `${name} (${tokenType === 'Quote' ? 'USD' : token})`,
    currency: viewTarget === 'volumeUSD' ? 'USD' : currency,
  }),
  color: getLineColor(lineObj),
  data,
  // meta: {}
});

interface LineChartTokenAccountData {
  id: string;
  label: string;
  token: string;
  exchangeName: string;
  exchangeId: number;
  currency: string;
}

const getTokenAccountObj = (
  token: string,
  data: LineChartDataEntry<{ key: string }>[],
  coordinatesData: { x: string; y: number }[],
  viewTarget: string,
): LineChartDataEntry<LineChartTokenAccountData>[] => {
  return data.map((entry) => {
    const parsed = JSON.parse(entry.id) as LineChartTokenAccountData;
    const parsedId = parsed.id.split(' ')[0];

    return {
      id: JSON.stringify({
        id: `tokens_${parsedId}`,
        label: parsed.label,
        token: token,
        exchangeName: parsed.exchangeName,
        exchangeID: parsed.exchangeId,
        currency: viewTarget === 'volumeUSD' ? 'USD' : parsed.currency,
      }),
      color: entry.color,
      data: coordinatesData,
      // meta: {}
    } as LineChartDataEntry<LineChartTokenAccountData>;
  });
};

const getTootalAccountObj = (
  data: LineChartDataEntry<{ key: string }>[],
  coordinatesData: { x: string; y: number }[],
) => ({
  id: JSON.stringify({
    id: `acconunts_${data[0].id}`,
    label: 'Summary (usd)',
    currency: 'USD',
  }),
  color: data[0].color,
  data: coordinatesData,
  // meta: {}
});

const getAccountObj = (
  accountData: LineChartDataEntry<{ key: string }>[],
  viewTarget: BalanceViewTarget,
) => {
  return accountData.map((entry, index) => {
    const parsed = JSON.parse(entry.id) as LineChartTokenAccountData;
    const parsedId = parsed.id.split(' ')[0];

    return {
      id: JSON.stringify({
        id: `${parsedId}_${index}`,
        label: parsed.label,
        exchangeName: parsed.exchangeName,
        exchangeID: parsed.exchangeId,
        currency: viewTarget === 'volumeUSD' ? 'USD' : parsed.currency,
      }),
      color: entry.color,
      data: entry.data,
      // meta: {}
    };
  });
};

const createLineChartDataEntry = (
  key: string,
  label: string,
  exchangeName: string | undefined,
  exchangeId: number | undefined,
  coordinates: { x: string; y: number }[],
  viewTarget: BalanceViewTarget,
  hue: number,
  token?: string,
): LineChartDataEntry<{ key: string }> => {
  return {
    id: JSON.stringify({
      id: `${key}_${token ?? label}`,
      label: `${label} `,
      token: token === 'USDT' ? 'USD' : token,
      exchangeName: `${exchangeName ?? ''}`,
      exchangeId: exchangeId,
      currency: viewTarget === 'volumeUSD' ? 'USD' : (token ?? 'Token'),
    } as LineChartTokenAccountData),
    color: getLineColor({ hue }),
    data: sortBy(coordinates, 'x'),
    meta: {
      key,
    },
  } as LineChartDataEntry<{ key: string }>;
};

const calculateData = (
  data: JsonBalanceHistoryExtended[],
  viewTarget: BalanceViewTarget,
  ignoreTokens: string[],
  excludeLastCoordinate: boolean,
  tokenType?: 'Base' | 'Quote',
) => {
  if (tokenType) {
    return map(data, (item) => {
      const {
        account,
        exchange,
        assets,
        key,
        hue,
        quoteToken = tokenType,
        baseToken = tokenType,
        accountName,
      } = item;
      const label = accountName ?? account?.label ?? key;
      const accountId = account?.exchange;

      const allCoordinates = flatMap(assets, (asset) => {
        if (asset.type.toLowerCase() !== tokenType.toLowerCase()) {
          return [];
        }

        return asset.history?.filter(({ token }) => !ignoreTokens.includes(token));
      });

      const groupedByDate = groupBy(allCoordinates, ({ date }) =>
        dayjs.utc(date, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm'),
      );

      let coordinates = map(groupedByDate, (entries, date) => {
        const totalVolume = entries.reduce((sum, { historyAmount }) => sum + historyAmount, 0);
        const totalVolumeUSD = entries.reduce(
          (sum, { historyAmount, quotePrice, price }) =>
            sum + historyAmount * (tokenType === 'Base' ? price : quotePrice),
          0,
        );

        return {
          x: date,
          y: viewTarget === 'volumeUSD' ? totalVolumeUSD : totalVolume,
        };
      });

      if (excludeLastCoordinate) {
        coordinates = coordinates.slice(0, -1);
      }
      return createLineChartDataEntry(
        key,
        item.accountName ?? label,
        exchange?.name,
        item.exchangeID ?? accountId,
        coordinates,
        viewTarget,
        hue,
        tokenType === 'Base' ? baseToken : quoteToken,
      );
    }).reverse();
  } else {
    const groupedData = groupBy(
      data,
      (item) => item.accountName ?? item.account?.label ?? item.key,
    );

    return map(groupedData, (items, label) => {
      const { accountLabel, exchange, accountName, exchangeName, hue } = items[0];

      const linechart = flatMap(items, (item) => {
        const { assets } = item;
        const baseHistory = find(assets, { type: 'base' })?.history ?? [];
        const quoteHistory = find(assets, { type: 'quote' })?.history ?? [];
        return [...baseHistory, ...quoteHistory];
      });
      const filteredLinechart = linechart?.filter(({ token }) => !ignoreTokens.includes(token));

      const groupedByDate = groupBy(filteredLinechart, ({ date }) =>
        dayjs.utc(date, 'YYYY-MM-DD HH:mm').local().format('YYYY-MM-DD HH:mm'),
      );

      let coordinates = map(groupedByDate, (entries, date) => {
        const totalVolume = entries.reduce((sum, { historyAmount }) => sum + historyAmount, 0);
        const totalVolumeUSD = entries.reduce(
          (sum, { historyAmount, quotePrice, price }) =>
            sum + historyAmount * (price || quotePrice),
          0,
        );

        return {
          x: date,
          y: viewTarget === 'volumeUSD' ? totalVolumeUSD : totalVolume,
        };
      });

      if (excludeLastCoordinate) {
        coordinates = coordinates.slice(0, -1);
      }

      const exchangeItemName = accountLabel === 'Wallet' ? exchangeName : exchange?.name;
      const labelItem = accountName ?? label;
      const exchangeIdItem = exchange?.id;
      const accountId = items[0].account?.exchange;

      return createLineChartDataEntry(
        `${label}`,
        labelItem,
        exchangeItemName,
        exchangeIdItem ?? accountId,
        coordinates,
        viewTarget,
        hue,
      );
    }).reverse();
  }
};

const calculatePoint = (
  xCoordinates: { x: string }[],
  data: LineChartDataEntry<{ key: string }>[],
) =>
  map(xCoordinates, (point) => ({
    x: point.x,
    y: reduce(
      data,
      (sum, item) => {
        const basePoint = find(item?.data, { x: point.x });

        return sum + (basePoint ? basePoint.y : 0);
      },
      0,
    ),
  }));

export const getLinearData = ({
  data,
  selection,
  viewTarget,
  viewChartGroupBy,
  ignoreTokens,
  viewCumulativeBalance,
  startDate,
  endDate,
}: {
  data: JsonBalanceHistoryExtended[];
  selection: string[];
  viewTarget: BalanceViewTarget;
  viewChartGroupBy: BalanceViewChartGroupBy;
  ignoreTokens: string[];
  viewCumulativeBalance: BalanceViewCumulativeBalance;
  startDate?: string;
  endDate?: string;
}): LinearDataType<never> => {
  const filteredData = filter(
    data,
    (item) => !item.isTotalDoubling && (!selection?.length || selection.includes(item.key)),
  );

  if (!filteredData.length) {
    return null;
  }

  const endDateData = filteredData.reduce<JsonBalanceHistoryItem[] | null>((acc, item) => {
    if (acc) return acc;
    const asset = item.assets.find((asset) => asset.type === 'quote' || asset.type === 'base');
    return asset ? asset.history : acc;
  }, null);

  const isMidnightDate = endDate === dayjs().startOf('day').format('YYYY-MM-DD HH:mm');

  const coordinates = endDateData
    ? getCoordinates(
        startDate,
        endDate,
        endDateData && !isMidnightDate ? endDateData[endDateData.length - 1].date : undefined,
      )
    : [];

  const accountData = calculateData(filteredData, viewTarget, ignoreTokens, isMidnightDate);
  const accountDataCordinates = calculatePoint(coordinates, accountData);
  const accountChartData = getAccountObj(accountData, viewTarget);
  const accountSummaryChartData = getTootalAccountObj(accountData, accountDataCordinates);

  const baseData = calculateData(filteredData, viewTarget, ignoreTokens, isMidnightDate, 'Base');
  const quoteData = calculateData(filteredData, viewTarget, ignoreTokens, isMidnightDate, 'Quote');

  const subtotalBaseCoordinates = calculatePoint(coordinates, baseData);

  const subtotalBase = getTotalObj(
    'Summary',
    filteredData[0]?.baseToken ?? 'Base',
    subtotalBaseCoordinates,
    viewTarget,
    { hue: filteredData[0]?.hue },
    'Base',
  );

  const subtotalQuoteCoordinates = calculatePoint(coordinates, quoteData);

  const subtotalQuote = getTotalObj(
    'Summary',
    filteredData[0]?.quoteToken ?? 'Quote',
    subtotalQuoteCoordinates,
    viewTarget,
    { hue: filteredData[0]?.hue, lightness: 50 },
    'Quote',
    filteredData[0]?.quoteToken,
  );

  const totalBase = getTotalObj(
    'Total',
    filteredData[0]?.baseToken ?? 'Base',
    subtotalBaseCoordinates,
    viewTarget,
    { hue: filteredData[0]?.hue },
    'Base',
  );

  const totalQuote = getTotalObj(
    'Total',
    filteredData[0]?.quoteToken ?? 'Quote',
    subtotalQuoteCoordinates,
    viewTarget,
    { hue: filteredData[0]?.hue, lightness: 50 },
    'Quote',
    filteredData[0]?.quoteToken,
  );

  const subtotalData = compact([subtotalQuote, subtotalBase]);
  const totalData = compact([totalQuote, totalBase]);

  const chartData = compact(
    flatMap(filteredData, (_: unknown, idx: number) => {
      const quote = quoteData[idx];
      const base = baseData[idx];

      if (quote.data.length === 0) {
        const pointsBase = calculatePoint(coordinates, [base]);
        return getTokenAccountObj(
          filteredData[0]?.baseToken ?? 'Base',
          [base],
          pointsBase,
          viewTarget,
        );
      }

      if (base.data.length === 0) {
        const pointsQuote = calculatePoint(coordinates, [quote]);
        return getTokenAccountObj(
          filteredData[0]?.quoteToken ?? 'Quote',
          [quote],
          pointsQuote,
          viewTarget,
        );
      }
      if (quote.data.length === 0 && base.data.length === 0) {
        return concat(totalData);
      }

      return [quote, base];
    }),
  ) as unknown as LineChartDataEntry<never>[];

  let tokensLinechartData = [];
  if (selection?.length) {
    tokensLinechartData =
      viewCumulativeBalance === false ? concat(subtotalData) : concat(chartData);
  } else {
    tokensLinechartData = concat(totalData);
  }

  let accountLinechartData = [];
  if (!selection.length) {
    const totalCoordinates = coordinates.map((coord) => {
      const totalY = accountChartData.reduce((sum, entry) => {
        const point = entry.data.find((d) => d.x === coord.x);
        return sum + (point ? point.y : 0);
      }, 0);
      return { x: coord.x, y: totalY };
    });

    accountLinechartData = [
      {
        id: JSON.stringify({
          id: 'Summary (USD)',
          currency: viewTarget === 'volumeUSD' ? 'USD' : 'Token',
        }),
        color: getLineColor({ hue: filteredData[0]?.hue, lightness: 50 }),
        data: totalCoordinates,
      },
    ];
  } else {
    accountLinechartData =
      viewCumulativeBalance === true ? accountChartData : compact([accountSummaryChartData]);
  }

  const linechartData = viewChartGroupBy === 'tokens' ? tokensLinechartData : accountLinechartData;
  return {
    linechartData,
  };
};

export const getCompareSummary = (
  uniqTokens: string[],
  data: JsonBalanceHistoryExtended[],
  selection: Key[],
  isSmallBalancesVisible = false,
  ignoreDlaBots = false,
) =>
  map(uniqTokens, (token) => {
    const accountsWithToken = filter(data, (item) => {
      const foundAsset = find(item.assets, { token });
      return !!foundAsset;
    });

    const isBase = some(accountsWithToken, (item) => {
      const foundAsset = find(item.assets, { token });
      return foundAsset?.type === 'base';
    });

    const isQuote = some(accountsWithToken, (item) => {
      const foundAsset = find(item.assets, { token });
      return foundAsset?.type === 'quote';
    });

    let tokenType = 'other';
    if (isBase) {
      tokenType = 'base';
    } else if (isQuote) {
      tokenType = 'quote';
    }

    return {
      token,
      type: tokenType,
      comparison: {
        startDate: first(data)?.assets[0]?.comparison?.startDate,
        startData: {
          price: getPricePerAsset({
            source: data,
            token,
            key: `comparison.startData.price`,
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
          volume: getTotalPerAsset({
            source: data,
            token,
            key: 'comparison.startData.volume',
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
          volumeUSD: getTotalPerAsset({
            source: data,
            token,
            key: 'comparison.startData.volumeUSD',
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
        },
        endDate:
          first(data)?.assets[0]?.comparison?.endHistoryItem?.date ??
          first(data)?.assets[0]?.comparison?.endDate,
        endData: {
          volume: getTotalPerAsset({
            source: data,
            token,
            key: 'comparison.endData.volume',
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
          volumeUSD: getTotalPerAsset({
            source: data,
            token,
            key: 'comparison.endData.volumeUSD',
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
          price: getPricePerAsset({
            source: data,
            key: 'comparison.endData.price',
            token,
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
        },
        diff: {
          volume: getTotalPerAsset({
            source: data,
            token,
            key: 'comparison.diff.volume',
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
          volumeUSD: getTotalPerAsset({
            source: data,
            token,
            key: 'comparison.diff.volumeUSD',
            selection,
            isSmallBalancesVisible,
            ignoreDlaBots,
          }),
        },
      } as NewComparisonItem,
    };
  });

export const getCompareInitialValues = ({
  balance,
  foundAsset,
  isStart,
  domain,
  loanStartDate = '',
}: SetInitialBalanceModal & {
  domain: ApplicationDomain;
  loanStartDate: string;
}) =>
  reduce(
    foundAsset,
    (acc, val) => {
      if (!val.comparison) return acc;

      const { startDate, endDate, startHistoryItem, endHistoryItem } = val.comparison;

      let date = isStart ? startDate : endDate;
      if (domain === ApplicationDomain.Loans && loanStartDate) {
        date = loanStartDate;
      }

      const historyItem = isStart ? startHistoryItem : endHistoryItem;

      // means undefined
      let amount = Number.MIN_SAFE_INTEGER;
      if (historyItem?.initialAmount > Number.MIN_SAFE_INTEGER) {
        amount = historyItem?.initialAmount;
      } else if (historyItem?.historyAmount > Number.MIN_SAFE_INTEGER) {
        amount = historyItem?.historyAmount;
      }

      return {
        ...acc,
        [val.token]: {
          accountID: balance.accountID,
          token: val.token,
          date,
          amount,
          type: (val.type ?? 'other') as SetInitialBalanceFormValues['type'],
        },
      };
    },
    {},
  );

export const getTransactionSummary = (
  transactions: JsonTransaction[],
  startDate?: string,
  endDate?: string,
) => {
  if (!startDate || !endDate) {
    return {};
  }

  const transactionsInRange = getTransactionsInRange({ transactions, startDate, endDate });

  return reduce(
    transactionsInRange,
    (acc, transaction) => {
      const key = `${transaction.accountID}_${transaction.currency}`;
      const type = transaction.type;
      if (!acc[key]) {
        acc[key] = {
          [type]: {
            volume: 0,
            volumeUSD: 0,
          },
        };
      }

      acc[key][type] = {
        volume: (acc[key][type]?.volume ?? 0) + transaction.volume,
        volumeUSD: (acc[key][type]?.volumeUSD ?? 0) + transaction.usd,
        currency: transaction.currency,
        date: transaction.date,
      };

      return acc;
    },
    {} as TransactionObj,
  );
};

const getTooltip = (
  startValue: string,
  endValue: string,
  depositValue: string,
  withdrawValue: string,
) => {
  const parts = [startValue, depositValue, withdrawValue].filter(Boolean);
  return `${endValue} - (${parts.length ? parts.join(' + ') : '0'})`;
};

export const getNetChangeValue = (
  startValue: number,
  endValue: number,
  depositValue: number,
  withdrawValue: number,
  token: string,
) => {
  const diffValue = endValue - (startValue + depositValue + withdrawValue);
  const formatValues = (value: number, currency: string) =>
    formatNumber(value, { ...defaultFormat, currency });

  return {
    diffValue,
    volumeHint: getTooltip(
      startValue ? formatValues(startValue, token) : '',
      formatValues(endValue, token),
      depositValue ? formatValues(depositValue, token) : '',
      withdrawValue ? formatValues(withdrawValue, token) : '',
    ),
    volumeUSDHint: getTooltip(
      startValue ? formatValues(startValue, 'USD') : '',
      formatValues(endValue, 'USD'),
      depositValue ? formatValues(depositValue, 'USD') : '',
      withdrawValue ? formatValues(withdrawValue, 'USD') : '',
    ),
  };
};

export const getTokenInfo = (list: JsonBalanceAsset[]) => {
  const tokens = groupBy(list, 'token');
  const tokenList: UniqueTokensList[] = [];
  each(tokens, (item, key) => {
    const calculateTotal = reduce(
      item,
      (sum, val) => {
        if (val?.type && val.type === 2) {
          return sum;
        }

        if (!val?.lastPrice) {
          return sum;
        }

        const total = val.volume * val.lastPrice;
        return sum + total;
      },

      0,
    );

    tokenList.push({
      token: key,
      isSmallBalance: Math.abs(calculateTotal) < smallTokensThreshold,
      type: item[0].type,
    });
  });

  return tokenList;
};
