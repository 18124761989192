import { version } from '../package.json';

export const PACKAGE_VERSION = version;
export const AUTH_BASE_URL = import.meta.env.VITE_AUTH_API_URL || 'http://localhost:3000';
export const CERBOS_API_URL = import.meta.env.VITE_CERBOS_API_URL || 'http://localhost:3592';
export const ADMIN_URL = import.meta.env.VITE_ADMIN_URL || 'http://localhost';
export const ADMIN_X_TOKEN = import.meta.env.VITE_ADMIN_X_TOKEN || '';
export const DEX_API_URL = import.meta.env.VITE_DEX_API_URL || 'http://localhost';
export const LINEAR_API_KEY = import.meta.env.VITE_LINEAR_API_KEY || '';
export const SENTRY_TRACES_SAMPLE_RATE = import.meta.env.VITE_SENTRY_TRACES_SAMPLE_RATE || 1.0;
export const WS_URL = import.meta.env.VITE_LOANS_WS_URL || 'ws://localhost';
export const IS_AUTHZ_NAVIGATION_ENABLED = true;
export const EMPTY_ARRAY = [];
