import React from 'react';
import ReactDOM from 'react-dom/client';
import { App } from './app';
import './instrument';

const rootDiv = document.getElementById('root')!;

if (!rootDiv) {
  throw new Error("The element #root wasn't found");
}

ReactDOM.createRoot(rootDiv).render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
