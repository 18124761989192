import { UINavigation, ANY_ID } from 'src/utils/cerbos/type';
import type { CerbosCheckResource } from 'src/utils/cerbos/type';

export const UINavigationActions = [
  'read:admin:servers',
  'read:admin:arbitrage',
  'read:admin:log',
  'read:admin:keys',
  'read:admin:users',
  'read:admin:roles',
  'read:admin:token-mapping',
  'read:loans:summary',
  'read:loans:overview',
  'read:loans:terms',
  'read:loans:balances',
  'read:loans:tasks',
  'read:loans:transactions',
  'read:loans:liquidity',
  'read:loans:trading',
  'read:retainers:overview-admin',
  'read:retainers:overview',
  'read:retainers:terms-admin',
  'read:retainers:terms',
  'read:retainers:balances-admin',
  'read:retainers:balances',
  'read:retainers:tasks-admin',
  'read:retainers:tasks',
  'read:retainers:transactions',
  'read:retainers:trading',
  'read:retainers:arbitrage-info',
  'read:retainers:clients',
];

type UINavigationCheckType = Omit<CerbosCheckResource, 'actions'> & {
  actions: typeof UINavigationActions;
};

export const UINavigationCheck: UINavigationCheckType = {
  resource: {
    kind: UINavigation.UINavigation,
    id: ANY_ID,
  },
  actions: UINavigationActions,
};
