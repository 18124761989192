import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { parseResponse } from 'src/store/utils';
import type { RequestStatus } from 'src/store/types';
import type { GenericError } from 'src/store/utils/errors';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import type { JsonBalance } from 'src/store/shared/balances/types';
import { listen } from '../actions';
import type { JsonRetainersExchange, JsonRetainersExchangesSuccess } from '../types';
import { RetainerCommands } from '../types';

export type RetainersLiquidityViewFilters = 'no' | 'supportedPairs';

interface RetainersOverviewState {
  requestStatus: RequestStatus;
  error: null | GenericError;

  exchanges: JsonRetainersExchange[] | null;
  balances: JsonBalance[] | null;

  supportedPairs: string[];
  viewFilter: RetainersLiquidityViewFilters;
  supportedExchanges: number[];
}

const initialState: RetainersOverviewState = {
  requestStatus: null,
  error: null,

  exchanges: null,
  balances: null,

  supportedPairs: [],
  supportedExchanges: [],
  viewFilter: 'supportedPairs',
};

const allowedCommands = [RetainerCommands.RetainerOverview];

const retainersOverviewSlice = createSlice({
  name: 'overview', // retainers.overview
  initialState,
  reducers: {
    setViewFilter(state, action: PayloadAction<RetainersLiquidityViewFilters>) {
      state.viewFilter = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(listen.pending, (state, action) => {
      const { isInitCall, section } = action.meta.arg;
      if (isInitCall && section === 'overview') {
        state.requestStatus = 'pending';
      }
    });

    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error } = parseResponse(action.payload, allowedCommands);

      if (skipProcessing || !json) return;

      const { command } = json;

      if (error) {
        state.error = error;
        return;
      }

      if (command === RetainerCommands.RetainerOverview) {
        const { exchanges, balances, supportedExchanges, pairs }: JsonRetainersExchangesSuccess =
          json;
        state.exchanges = exchanges;
        state.balances = balances;
        state.requestStatus = 'success';

        state.supportedExchanges = supportedExchanges;
        state.supportedPairs = pairs;
      }
    });
  },
});

export const retainersOverviewActions = {
  ...retainersOverviewSlice.actions,
};

export default retainersOverviewSlice.reducer;
