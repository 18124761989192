import { createAsyncThunk } from '@reduxjs/toolkit';
import omit from 'lodash/omit';
import { wsLoansSend } from 'src/store/ws-loans/actions';
import type {
  DeleteApiKeyPayload,
  CreateApiKeyPayload,
  ExchangeObjItems,
} from 'src/store/apiKeys/types';
import { AdminApiKeyEndpoints, ApiCommands } from 'src/store/apiKeys/types';
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { ADMIN_URL, ADMIN_X_TOKEN } from 'src/constants';

export interface ListenApiKeysPayload {
  section: 'api';
  isInitCall: boolean;
}

export const listen = createAsyncThunk('apiKeys/listen', (data: ListenApiKeysPayload) => {
  console.log('Listening to API keys:', omit(data, 'isInitCall'));
});

export const createApiKey = createAsyncThunk(
  'apiKeys/createApiKey',
  (data: CreateApiKeyPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.CreateAPIKey,
        ...data,
      }),
    ),
);

export const deleteApiKey = createAsyncThunk(
  'apiKeys/deleteApiKey',
  (data: DeleteApiKeyPayload, thunkApi) =>
    thunkApi.dispatch(
      wsLoansSend({
        command: ApiCommands.DeleteAPIKey,
        ...data,
      }),
    ),
);

export const rtkApiKey = createApi({
  reducerPath: 'apiKeys/rtk',
  baseQuery: fetchBaseQuery({
    baseUrl: ADMIN_URL,
    prepareHeaders: (headers) => {
      headers.set('x-access-token', ADMIN_X_TOKEN);
      return headers;
    },
  }),
  endpoints: (build) => ({
    getExchange: build.mutation<ExchangeObjItems, void>({
      query: () => ({
        url: AdminApiKeyEndpoints.Exchange,
        method: 'GET',
      }),
    }),
  }),
});

export const { useGetExchangeMutation } = rtkApiKey;
