import { createSlice } from '@reduxjs/toolkit';
import { actionOnMessage } from 'src/store/ws-loans/actions';
import { parseResponse } from 'src/store/utils';
import { LoanCommands } from 'src/store/loans/types';
import type { LoansOverviewState } from './types';

const initialState: LoansOverviewState = {
  error: null,
  requestStatus: null,
};

const allowedCommands = Object.values(LoanCommands);

const loansOverviewSlice = createSlice({
  name: 'overview',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionOnMessage, (state, action) => {
      const { json, skipProcessing, error, errorCommand } = parseResponse(
        action.payload,
        allowedCommands,
      );

      if (skipProcessing || !json) return;

      console.debug(`loans/processing ${actionOnMessage.toString()}`, action, allowedCommands);

      if (error) {
        state.error = error;

        switch (errorCommand) {
          case LoanCommands.UpdateLoan:
            state.requestStatus = 'error';
            break;
          default:
        }
      }
    });
  },
});

export const loansOverviewActions = {
  ...loansOverviewSlice.actions,
};

export default loansOverviewSlice.reducer;
