import { createSlice, isAnyOf } from '@reduxjs/toolkit';

import {
  actionConnect,
  actionDisconnect,
  actionOnClose,
  actionOnClosed,
  actionOnError,
  actionOnMessage,
  actionOnOpen,
  actionOnReconnectBegin,
  actionSend,
} from 'src/store/ws-loans/actions';
import { startListening } from 'src/store/middleware/listenerMiddleware';
import { authActions, loginDomain } from 'src/store/auth/slice';
import { ApplicationDomain, skyRouter } from 'src/sky-router';
import { signUpApi } from 'src/store/signup/actions';
import { ensureAccessToken } from 'src/store/shared/rtkAuthApi/refresh-auth';
import type { WsError } from 'src/store/ws-loans/types';
import { selectAccessToken } from 'src/store/signup/selectors';
import { getDefaults } from 'src/hooks/useSection';
import { checkAccessUiNavigation } from 'src/utils/cerbos/checkAccessUiNavigation';
import type { Store } from 'redux';
import { requiresLoginCommand } from 'src/utils/routing/validation';

export interface WsLoansState {
  status: 'idle' | 'connecting' | 'disconnecting' | 'connected';
  error: WsError | null;
  isReconnecting: boolean;
}

const initialState: WsLoansState = {
  status: 'idle',
  error: null,
  isReconnecting: false,
};

const wsLoansSlice = createSlice({
  name: 'wsLoans',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(actionConnect, (state, action) => {
      console.log(`${actionConnect.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionConnect.toString()}`, action);
      if (state.status === 'connected') {
        state.isReconnecting = true;
      }
      state.status = 'connecting';
    });

    builder.addCase(actionOnReconnectBegin, (state, action) => {
      console.log(`${actionOnReconnectBegin.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionOnReconnectBegin.toString()}`, action);
      if (state.status === 'connected' || state.status === 'idle') {
        state.isReconnecting = true;
      }
      state.status = 'connecting';
    });

    builder.addCase(actionDisconnect, (state, action) => {
      console.log(`${actionDisconnect.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionDisconnect.toString()}`, action);
      state.status = 'disconnecting';
    });

    builder.addCase(actionOnOpen, (state, action) => {
      console.log(`${actionOnOpen.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionOnOpen.toString()}`, action);
      state.status = 'connected';
      state.isReconnecting = false;
      state.error = null;
    });

    builder.addCase(actionOnClose, (state, action) => {
      console.log(`${actionOnClose.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionOnClose.toString()}`, action);
      state.status = 'idle';
      // state.error = null;
    });

    builder.addCase(actionOnClosed, (state, action) => {
      console.log(`${actionOnClosed.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionOnClosed.toString()}`, action);
      state.status = 'idle';
      // state.error = null;
    });

    builder.addCase(actionSend, (state, action) => {
      console.log(`${actionSend.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionSend.toString()}`, action);
    });

    builder.addCase(actionOnMessage, (state, action) => {
      console.log(`${actionOnMessage.toString()} ${action.payload.message}`);
      try {
        console.debug(`${actionOnMessage.toString()}`, JSON.parse(action.payload.message));
      } catch (e) {
        console.error('Failed to parse message', e);
      }
    });

    builder.addCase(actionOnError, (state, action) => {
      console.error(`${actionOnError.toString()} ${JSON.stringify(action.payload)}`);
      console.debug(`processing ${actionOnError.toString()}`, action);
      state.status = 'idle';
      state.error = action.payload;
    });
  },
});

startListening({
  matcher: isAnyOf(actionOnOpen, signUpApi.endpoints.postAuthenticate2FA.matchFulfilled),
  effect: async (action, listenerApi) => {
    const state = listenerApi.getState();
    const accessToken = selectAccessToken(state);

    await ensureAccessToken(listenerApi, accessToken);

    const resource = await checkAccessUiNavigation(listenerApi as unknown as Store);
    const [defaultDomain] = getDefaults(resource);

    const forceLogin = action.type === actionOnOpen.type;
    const domain = skyRouter.getDomain() ?? defaultDomain ?? ApplicationDomain.Loans;
    listenerApi.dispatch(authActions.setAuthDomain({ domain }));
    const pathname = skyRouter.getPathname();

    if (requiresLoginCommand(pathname)) {
      await listenerApi.dispatch(loginDomain({ domain, forceLogin }));
    }
  },
});

startListening({
  actionCreator: actionSend,
  effect: async (_, api) => {
    const accessToken = selectAccessToken(api.getState());
    await ensureAccessToken(api, accessToken);
  },
});

export const wsLoansActions = wsLoansSlice.actions;
// export const { startConnecting, connectionEstablished } = wsLoansSlice.actions;

export default wsLoansSlice.reducer;
