import keys from 'lodash/keys';

export const cryptoCurrencies: Record<string, string> = {
  USDT: '$',
  USDC: '$',
  BUSD: '$',
  EURT: '€',
  BTC: '₿',
  XBT: '₿',
};

export const supportedCurrencies = [
  ...Intl.supportedValuesOf('currency'),
  ...keys(cryptoCurrencies),
];
