export enum AuthCommands {
  authOK = 'authOK',
  authRequired = 'authRequired',
  NotConnected = 'NotConnected',
  Auth = 'Auth',
  Authorization = 'Authorization',
  '2FA' = '2FA',
  Login = 'Login',
  Logout = 'Logout',
  // ResetPassword = 'ResetPassword',
}
