import type { CustomFormats, IntlConfig } from 'react-intl';
import { createIntlCache } from 'react-intl';

export const intlCache = createIntlCache();

const intlFormats: CustomFormats = {
  number: {
    engineering: {
      notation: 'engineering',
    },
    scientific: {
      notation: 'scientific',
    },
    highPrecision: {
      notation: 'standard',
      maximumFractionDigits: 12,
    },
    precision: {
      notation: 'standard',
      maximumFractionDigits: 2,
    },
    compactLong: {
      notation: 'compact',
      compactDisplay: 'short',
      trailingZeroDisplay: 'auto',
    },
  },
};

const onIntlError = (err: Error) => {
  throw err;
};

export const defaultIntlConfig: IntlConfig = {
  locale: 'en',
  defaultLocale: 'en',
  messages: {},
  formats: intlFormats,
  onError: onIntlError,
};
