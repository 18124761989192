import type { JwtPayload } from 'src/store/shared/rtkAuthApi/types';

export interface Principal {
  id: string;
  roles: string[];
}

export const getPrincipal = (jwtPayload?: JwtPayload): Principal => {
  if (jwtPayload?.sub) {
    return {
      id: `${jwtPayload.sub}`,
      roles: !jwtPayload?.roles?.length ? ['anonymous'] : jwtPayload.roles,
    };
  }

  return {
    id: 'ANONYMOUS_USER',
    roles: ['anonymous'],
  };
};
