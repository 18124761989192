import type { FormatNumberOptions, IntlShape } from 'react-intl';
import isNil from 'lodash/isNil';
import includes from 'lodash/includes';
import min from 'lodash/min';
import max from 'lodash/max';
import { cryptoCurrencies, supportedCurrencies } from './constants';

export const getNumberFormatter =
  (intl: IntlShape) => (value?: number | null, opts?: FormatNumberOptions) => {
    if (isNil(value)) {
      return 'N/A';
    }

    const getNumOpts = () => {
      const defaultOpts: FormatNumberOptions = {
        ...opts,
        ...(opts?.currency
          ? {
              currency: opts.currency,
              style: includes(supportedCurrencies, opts?.currency) ? 'currency' : opts.style,
              currencyDisplay: 'narrowSymbol',
            }
          : {}),
      };

      // override default options for currency format
      if (opts?.currency && !defaultOpts.minimumFractionDigits) {
        const absValue = Math.abs(value);
        if (absValue === 0) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 0;
          }
        } else if (absValue > 0 && absValue <= 0.0001) {
          defaultOpts.maximumSignificantDigits = 2;
        } else if (absValue > 0.0001 && absValue <= 1) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 2;
            defaultOpts.maximumSignificantDigits = 2;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumSignificantDigits = 4;
          }
          if (defaultOpts.format === 'highPrecision') {
            defaultOpts.maximumSignificantDigits = 4;
          }
        } else if (absValue > 1 && absValue <= 10) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 1;
            defaultOpts.maximumFractionDigits = 2;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumSignificantDigits = 3;
          }
          if (defaultOpts.format === 'highPrecision') {
            defaultOpts.maximumSignificantDigits = 4;
          }
        } else if (absValue > 10 && absValue <= 1_000) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 0;
            defaultOpts.maximumFractionDigits = 1;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumFractionDigits = 1;
          }
        } else if (absValue > 1_000 && absValue <= 1_000_000) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 1;
            defaultOpts.maximumFractionDigits = 1;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumFractionDigits = 0;
          }
        } else if (absValue > 1_000_000) {
          if (defaultOpts.format === 'compactLong') {
            defaultOpts.minimumFractionDigits = 2;
            defaultOpts.maximumFractionDigits = 2;
          }
          if (defaultOpts.format === 'precision') {
            defaultOpts.maximumFractionDigits = 0;
          }
        } else if (defaultOpts.format === 'compactLong') {
          defaultOpts.minimumFractionDigits = 2;
          defaultOpts.maximumFractionDigits = 2;
        }
      }

      return {
        ...defaultOpts,

        // make sure we don't have errors with minimumFractionDigits and maximumFractionDigits
        ...(opts?.minimumFractionDigits !== undefined && {
          minimumFractionDigits: min([
            opts?.minimumFractionDigits,
            defaultOpts.minimumFractionDigits,
          ]),
        }),
        ...(opts?.maximumFractionDigits !== undefined && {
          maximumFractionDigits: max([
            opts?.maximumFractionDigits,
            defaultOpts.maximumFractionDigits,
          ]),
        }),
      };
    };

    const formatOpts = getNumOpts();

    // for known crypto currencies, replace the currency symbol
    if (opts?.currency && !isNil(cryptoCurrencies[opts?.currency])) {
      return intl
        .formatNumber(value, {
          ...formatOpts,
          currency: 'USD',
        })
        .replace('$', cryptoCurrencies[opts?.currency]);
    }

    try {
      return intl.formatNumber(value, formatOpts);
    } catch (ignoreError) {
      // NOTICE: this will cause tons of error logs in the console
      // which dramatically reduce the performance in the dev mode
      // console.error('Failed to format number', error);

      // fallback to USD if currency is not supported,
      // e.g. unknown crypto currency is replaced with currency name
      if (opts?.currency) {
        return intl.formatNumber(value, {
          ...formatOpts,
          currency: 'USD',
          style: 'decimal',
        });
      }

      // fallback to unformatted value as string
      return `${value}`;
    }
  };
