import { createSelector } from '@reduxjs/toolkit';
import type { RootState } from 'src/store';
import { walletsApi } from 'src/store/cold-wallets/actions';
import type {
  JsonBalance,
  JsonBalanceAsset,
  JsonBalanceHistoryAsset,
  NewComparisonItem,
} from 'src/store/shared/balances/types';
import map from 'lodash/map';

import { selectBalancesSupportedTokens } from 'src/store/shared/balances/selectors';
import type {
  AllProjectWalletSnapshot,
  WalletAsset,
  WalletSnapshot,
} from 'src/store/cold-wallets/types';
import reduce from 'lodash/reduce';
import type { JsonBalanceHistoryItem } from 'src/store/shared/balance-history/types';
import dayjs from 'src/utils/formatting/dates';
import {
  selectBalanceHistoryCurrentDate,
  selectComparisonEndDate,
  selectComparisonStartDate,
} from 'src/store/shared/balance-history/selectors';

import { selectTokenMapData } from 'src/store/shared/tokenMap/selectors';
import groupBy from 'lodash/groupBy';
import flatMap from 'lodash/flatMap';
import sortBy from 'lodash/sortBy';
import type { TokenTypeMap } from 'src/store/shared/cold-wallets/types';
import { parseWallet } from 'src/store/utils/wallet';

const selectTokenTypeMap = createSelector(selectBalancesSupportedTokens, (supportedTokens) =>
  reduce(
    supportedTokens,
    (acc: TokenTypeMap, { token, type }: { token: string; type: number }) => {
      acc[token] = type;
      return acc;
    },
    {} as TokenTypeMap,
  ),
);

const makeSnapshotMapper =
  (tokenTypeMap: TokenTypeMap, currentSnapshotDate: string) =>
  (
    asset: JsonBalanceHistoryAsset | JsonBalanceAsset,
    walletId: string,
    snapshot?: WalletSnapshot,
  ): JsonBalanceHistoryItem => ({
    token: asset.token,
    accountID: walletId,
    date: snapshot ? dayjs.utc(snapshot.date).format('YYYY-MM-DD HH:mm') : currentSnapshotDate,
    type: snapshot ? (tokenTypeMap[asset.token] ?? 2) : (asset as JsonBalanceAsset).type,
    price: asset.lastPrice,
    historyAmount: snapshot
      ? (asset as JsonBalanceHistoryAsset).history[0].historyAmount
      : (asset as JsonBalanceAsset).volume,
    initialAmount: Number.MIN_SAFE_INTEGER,
    quotePrice: snapshot
      ? (asset as JsonBalanceHistoryAsset).history[0].quotePrice
      : asset.lastPrice,
  });

export const getColdWalletsSelectors = (selectTarget: (state: RootState) => string) => {
  const selectColdWallets = createSelector(
    selectTarget,
    (state: RootState) => state.coldWalletApi,
    selectTokenTypeMap,
    selectTokenMapData,
    (loanId, walletState, tokenTypeMap, tokensMap) => {
      if (!loanId) return [];
      const { data, error } = walletsApi.endpoints.getColdWallets.select(+loanId)({
        coldWalletApi: walletState,
      });

      const coldWallets = parseWallet(data, error);

      return map(coldWallets, (wallet) => {
        let formattedBalances: JsonBalanceAsset[] = [];

        try {
          const parsedBalances = JSON.parse(wallet.balances) as { assets: WalletAsset[] };
          formattedBalances = Array.isArray(parsedBalances.assets)
            ? parsedBalances.assets.map((assets) => {
                const token = tokensMap[assets.symbol] || assets.symbol;
                return {
                  token,
                  lastPrice: assets.price,
                  volume: assets.balance,
                  volumeUSD: assets.balanceUsd,
                  type: tokenTypeMap[token] ?? 2,
                  isDoubling: false,
                  isSmallBalance: false,
                  chain: assets?.blockchain,
                  leverage: 1, // Assuming leverage is 1 as it's not provided in the data
                };
              })
            : [];
        } catch (error) {
          console.error('Error parsing wallet balances', error);
        }

        return {
          account: {
            name: wallet.id.toString(),
            label: wallet.name,
            status: 1,
            group: 0,
            exchange: 0, // Assuming exchange is 0 as it's not provided in the data
            message: '',
            proxy: '',
          },
          accountID: wallet.id.toString(),
          accountName: wallet.name,
          accountLabel: 'Wallet',
          assets: formattedBalances,
          exchangeID: wallet.projectId + 10000000000,
          exchangeName: formattedBalances[0]?.chain,
          updated: dayjs.utc(wallet.updated).format('YYYY-MM-DD HH:mm'),
        } as JsonBalance;
      });
    },
  );

  const selectWalletsSnapshots = createSelector(
    selectTarget,
    (state: RootState) => state.coldWalletApi,
    selectComparisonStartDate,
    selectComparisonEndDate,
    selectColdWallets,
    selectTokenTypeMap,
    selectTokenMapData,
    selectBalanceHistoryCurrentDate,
    (
      loanId,
      walletState,
      startDate,
      endDate,
      coldWallets,
      tokenTypeMap,
      tokensMap,
      currentSnapshotDate,
    ) => {
      if (!loanId) return [];

      const formatedStartDate = dayjs(startDate).format('YYYY-MM-DD');
      const formatedEndDate = dayjs(endDate).format('YYYY-MM-DD');
      const snapshotsResult = walletsApi.endpoints.getWalletProjectSnapshot.select({
        projectId: +loanId,
        startDate: formatedStartDate,
        endDate: formatedEndDate,
      })({ coldWalletApi: walletState });

      const snapshotsData: AllProjectWalletSnapshot | undefined = snapshotsResult?.data;

      if (!snapshotsData) return [];
      const effectiveSnapshotDate = currentSnapshotDate ?? dayjs.utc().format('YYYY-MM-DD HH:mm');
      const mapSnapshot = makeSnapshotMapper(tokenTypeMap, effectiveSnapshotDate);

      const formattedSnapshots = snapshotsData.data.flatMap((snapshot) => {
        let formattedBalances: JsonBalanceHistoryAsset[] = [];

        try {
          const parsedBalances = JSON.parse(snapshot.balances) as { assets: WalletAsset[] };
          formattedBalances = Array.isArray(parsedBalances.assets)
            ? parsedBalances.assets.map((asset) => {
                const token = tokensMap[asset.symbol] || asset.symbol;
                return {
                  token,
                  lastPrice: asset.price,
                  leverage: 1, // Assuming leverage is 1 as it's not provided in the data
                  comparison: {} as NewComparisonItem, // Added to match the JsonBalanceHistoryAsset interface
                  history: [
                    {
                      accountID: snapshot.id.toString(),
                      date: dayjs(snapshot.date).format('YYYY-MM-DD HH:mm'),
                      historyAmount: asset.balance,
                      initialAmount: Number.MIN_SAFE_INTEGER,
                      price: asset.price,
                      quotePrice: asset.price,
                      token,
                      type: tokenTypeMap[token] ?? 2,
                    },
                  ],
                  isDoubling: false,
                  isSmallBalance: false,
                  type:
                    tokenTypeMap[token] !== undefined && tokenTypeMap[token] !== null
                      ? String(tokenTypeMap[token])
                      : 'other',
                };
              })
            : [];
        } catch (error) {
          console.error('Error parsing snapshot balances', error);
        }

        return formattedBalances.map((balance) =>
          mapSnapshot(balance, snapshot.walletId.toString(), snapshot),
        );
      });

      const groupedSnapshots = groupBy(formattedSnapshots, (item) =>
        dayjs(item.date).format('YYYY-MM-DD'),
      );

      const snapshots = flatMap(groupedSnapshots, (items, dayKey) => {
        const midnightDate = dayjs(dayKey).startOf('day').add(-1, 'minute');

        // sort by date, to make sure that the first snapshot is the earliest
        const sortedItems = sortBy(items, 'date');

        return map(sortedItems, (item, idx) => {
          // override date of the first snapshot
          if (idx === 0) {
            return {
              ...item,
              date: midnightDate.format('YYYY-MM-DD HH:mm'),
            };
          }

          return item;
        });
      });

      const currentDate = dayjs().format('YYYY-MM-DD');
      let currentBalances: JsonBalanceHistoryItem[] = [];
      if (formatedEndDate === currentDate) {
        currentBalances = coldWallets.flatMap((wallet) =>
          wallet.assets.map((asset) => mapSnapshot(asset, wallet.accountID)),
        );
      }

      return [...snapshots, ...currentBalances];
    },
  );

  return { selectColdWallets, selectWalletsSnapshots };
};
