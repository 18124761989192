import type { Store } from 'redux';
import capitalize from 'lodash/capitalize';
import type { RootState } from 'src/store';
import { skyCerbosClient } from 'src/sky-ui-lib/SkyAuthz/skyCerbosClient';
import { UINavigationCheck } from 'src/utils/cerbos/UINavigation';
import { selectJwtPrincipal } from 'src/store/signup/selectors';
import { selectNavigation } from 'src/store/navigation/selector';
import type { ApplicationDomain } from 'src/sky-router';
import { skyRouter } from 'src/sky-router';
import { navigationPermissionsActions } from 'src/store/navigation/slice';

let prevUrl = '';

const getDomain = (prevUrl: string) => {
  return prevUrl ? (capitalize(prevUrl.split('/')[1]) as ApplicationDomain) : '';
};

export const checkAccessUiNavigation = async (store: Store, url?: URL) => {
  const state = store.getState() as RootState;
  const data = selectNavigation(state);
  const prevDomain = getDomain(prevUrl);

  if (prevDomain === skyRouter.getDomain(url)) {
    prevUrl = skyRouter.getPathname(url);
  }

  if (prevUrl === skyRouter.getPathname(url) && data) {
    return data;
  }

  const principal = selectJwtPrincipal(state);

  const resource = await skyCerbosClient.checkResource({
    principal,
    ...UINavigationCheck, // Ensure UINavigationCheck is defined
  });

  store.dispatch(
    navigationPermissionsActions.setData({
      data: resource.allowedActions(),
      isLoading: 'success',
    }),
  );

  prevUrl = skyRouter.getPathname(url);
  return resource.allowedActions();
};
