import type { JsonFailure } from 'src/store/types';

export interface GenericError {
  error: boolean;
  errorCommand?: string;
  title: string;
  description: string;
}

interface ErrorMessage {
  title: string;
  getDescription?: (json: JsonFailure) => string;
}

type ErrorMessageMap = Record<string, ErrorMessage>;

const errorMessageMap: ErrorMessageMap = {
  // auth
  Authorization: {
    title: 'Authorization Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  '2FA': {
    title: '2FA Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  Login: {
    title: 'Login Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },

  // shared
  Accounts: {
    title: 'Accounts Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  EnableAccount: {
    title: 'Account Status Update Error',
    getDescription: (json: JsonFailure) =>
      `Sorry, the error has occured on updating account status. ${json.message}`,
  },
  DisableAccount: {
    title: 'Account Status Update Error',
    getDescription: (json: JsonFailure) =>
      `Sorry, the error has occured on updating account status. ${json.message}`,
  },
  Exchanges: {
    title: 'Exchanges Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },

  // loans
  Loans: {
    title: 'Loans Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  LoanStats: {
    title: 'Loans Stats Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  AddLoan: {
    title: 'Loans Error',
    getDescription: (json: JsonFailure) =>
      `Sorry, the error has occured on creating a new loan. ${json.message}`,
  },
  GetReport: {
    title: 'Report Download Error',
    getDescription: (json: JsonFailure) =>
      `Sorry, the error has occured on report send. ${json.message}`,
  },
  AccountDetails: {
    title: 'Account Details Error',
    getDescription: (json: JsonFailure) =>
      `Sorry, the error has occured on fetching account details. ${json.message}`,
  },
  // strike prices
  AddStrikePrice: {
    title: 'Add Strike Price Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  EditStrikePrice: {
    title: 'Edit Strike Price Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  RemoveStrikePrice: {
    title: 'Remove Strike Price Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  // committed funds
  AddCommittedFunds: {
    title: 'Add Committed Funds Error',
    getDescription: (json: JsonFailure) =>
      `Sorry, the error has occured on updating committed funds. ${json.message}`,
  },

  // retainers
  Projects: {
    title: 'Retainers Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  // retainers admin panel
  AdminPanel: {
    title: 'Admin Panel Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  BindAccount: {
    title: 'Bind Account Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },

  // hide loan
  HideLoan: {
    title: 'Archive Loan Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },

  // api keys
  GetAPIKey: {
    title: 'Get API Key Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  LinkLoan: {
    title: 'Link Loan Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  UnLinkLoan: {
    title: 'UnLink Loan Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  DeleteAPIKey: {
    title: 'Delete API Key Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
  CreateSubAccount: {
    title: 'Add Sub Account Error',
    getDescription: (json: JsonFailure) => `${json.message}`,
  },
};

export function registerErrorMessage(command: string, errorMessage: ErrorMessage) {
  errorMessageMap[command] = errorMessage;
}

export const onError = (json: JsonFailure) => {
  const { errorCommand } = json;
  const error = errorMessageMap[errorCommand];

  if (error) {
    return {
      error: true,
      errorCommand,
      title: error.title,
      description: error.getDescription
        ? error.getDescription(json)
        : 'Sorry, the unexpected error has occured',
    };
  }

  return null;
};

export const onParseError = (err: typeof Error): GenericError => {
  console.error('JSON.parse error', err);
  return {
    error: true,
    title: `Error on JSON.parse`,
    description: err.toString(),
  };
};
