import type { NavigationState } from 'src/store/navigation/slice';

interface RootState {
  navigationPermissions: NavigationState;
}

export const selectNavigation = (state: RootState) => state.navigationPermissions.data;

export const selectNavigationIsLoading = (state: RootState) =>
  state.navigationPermissions.isLoading === 'pending';
